import * as angular from 'angular';
import CrossBlocDataUpdatesController from "../controllers/crossBlocDataUpdates.controller";

"use strict";

let crossBlocDataUpdatesComponent =  {
    templateUrl: 'components/field/template/cross-bloc-data-updates/component.html',
    controller: CrossBlocDataUpdatesController,
    controllerAs: 'cbdu',
    bindings: {
        scanStation: '=',
        field: '='
    }
}

angular.module('FieldModule')
    .component('crossBlocDataUpdatesComponent', crossBlocDataUpdatesComponent);