import * as angular from 'angular';

"use strict";

angular.module('DownloadModule')
    .controller('DownloadController', DownloadController);

DownloadController.$inject = ['$scope', 'DownloadService', 'DownloadFactory', 'TemplateService'];

// Controller Constructor
function DownloadController($scope, DownloadService, DownloadFactory, TemplateService) {

    let vm = this;
    vm.main = '';
    vm.scanStation = '';
    vm.data = '';
    vm.showTemplateBloc = null;
    vm.templateFileList = null;
    vm.downloadFactory = null;
    vm.downloadService = null;
    vm.exportFromData = null;
    vm.rowId = null;
    vm.rowKey = null;
    vm.psc = null;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {
            // Assigning the scan station object and main
            $scope.main = vm.main;
            $scope.scanStation = vm.scanStation;
            $scope.data = vm.data;
            $scope.exportFromData = vm.exportFromData;
            $scope.rowId = vm.rowId;
            $scope.rowKey = vm.rowKey;
            $scope.psc = vm.psc;

            vm.downloadFactory = DownloadFactory;
            vm.downloadService = DownloadService;
            vm.templateService = TemplateService;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will load the template file list variable
     */
    vm.loadTemplateFileList = async (scanStationId) => {
        try {
            if (vm.templateFileList === null) {
                vm.templateFileList = await vm.downloadService.loadFileList(scanStationId);
                $scope.$apply();
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will load all scan station template elements
     * for each scan station.
     *
     * @param scanStationId
     */
    vm.getScanStationTemplates = async (scanStationId) => {
        try {

            if (vm.templateFileList === null) {
                vm.templateFileList = await vm.templateService.loadScanStationTemplates(scanStationId);
                $scope.$apply();
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will download a template.
     */
    vm.templateDownload = async (scanStationId, dataToAdd, chosenTemplateFile, pdfOrWord, rowId = null, emailAddresses = null) => {
        try {

            vm.waitingForTemplate = true;
            try {
                let response = await vm.downloadService.templateDownload(scanStationId, dataToAdd, chosenTemplateFile, pdfOrWord, rowId, emailAddresses);
                if(emailAddresses == null) {
                    let blob = new Blob([response.data], {
                        type: 'application/octet-stream'
                    });

                    let URL = window.URL || window.webkitURL;

                    vm.downloadFactory.downloadUrl = URL.createObjectURL(blob);
                    if (pdfOrWord != 'pdf') {
                        vm.downloadFactory.filename = vm.scanStation.stationName + '.' + chosenTemplateFile.split('.').pop();
                    } else {
                        vm.downloadFactory.filename = vm.scanStation.stationName + '.pdf';
                    }

                    let element = await document.getElementById('downloadPdfBtn');
                    vm.waitingForTemplate = false;

                    $scope.$apply();
                    element.click();
                } else {
                    vm.waitingForTemplate = false;
                    $scope.$apply();
                    alert('Attachment has been successfully sent.');

                }
            } catch (err) {
                alert('Something has gone wrong with this export');
                vm.waitingForTemplate = false;
                $scope.$apply();
                console.log(err);
                return false;
            }


        } catch (e) {
            console.log(e);
        }
    }

    vm.buildMailToAddresses = async (source) => {
        try {
            vm.emailAddresses = '';

            let dataSource = vm.scanStation.scanStationObj.dataToAdd;

            if (source == 'in-data') {
                dataSource = vm.scanStation.scanStationObj.liveData[vm.rowKey];
            }

            vm.emailAddresses = await DownloadFactory.buildMailToAddresses(dataSource, vm.scanStation.scanStationObj.scanStationFields);
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

}

export default DownloadController;