import * as angular from 'angular';

"use strict";

angular.module('DragDropModule')
       .factory('dragDropFactory', dragDropFactory);

dragDropFactory.$inject = [];

/**
 * Object that will create a Drag Drop factory, all methods of factory
 * will be available from outside.
 */
function dragDropFactory() {
    let factory = this;
    return factory;
}

