import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import CreateUserPopUpController from "./controllers/create-user-popup.controller";

"use strict";

class CreateUserPopUp extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/createUserPopUp';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        fc: '<',
        field: '<',
    }


    // This will set what will be the controller of this component
    public controller : any = CreateUserPopUpController;

    // This will set an alias
    public controllerAs: string = 'cupo';
}

angular.module('FieldModule').component('createUserPopup', <StandardComponent> new CreateUserPopUp);