import * as angular from 'angular';
import * as moment  from "moment";

"use strict";

angular.module('CalendarModule')
    .factory('CalendarFactory', CalendarFactory)
    .config(CalendarConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function CalendarConfiguration($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

CalendarFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Calendar Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {CalendarFactory}
 * @constructor
 */
function CalendarFactory($http, Configuration) {

    let factory = this;

    /**
     * This is the main function to get the calendar data
     * @param scanStationId
     * @param jobID
     * @param origin
     * @param calendarFromDate
     * @param calendarToDate
     * @param calendarPrimaryFieldFormat
     * @returns {*}
     */

    factory.getCalendarData = function (scanStation) {
        let apiBase = Configuration.getApiUrl() + 'get-calendar-data';
        let config = {
            params: {
                scanStationID: scanStation.id,
                jobID: scanStation.scanStationObj.jobID,
                origin: scanStation.scanStationObj.stateName,
                calendarFromDate: scanStation.calendarFromDate,
                calendarToDate: scanStation.calendarToDate,
                calendarPrimaryFieldFormat: scanStation.calendarPrimaryFieldFormat
            }
        };

        return $http.get(apiBase, config);
    };


    /**
     * This will get the data from the function CalendarFactory.getCalendarData
     * removed wrong indexes, making sure that we have counted correct each
     * day, if it is a new one int he array index it will be starting the object
     * else will be iterating the count and changing the tittle for the correct one.
     *
     * At the end it is removed the count index and transformed into
     * normal array flow from the previous array index date.
     *
     * @param data
     */
    factory.buildingCalendarEvents = function (data, calendarPrimaryFieldFormat) {

        let entryValues = <any> [];
        let currentDate = '';
        let currentCount = 1;

        // getting an array of the current data
        let processingData = data.data;
        let singularText = 'Entry';
        let pluralText = 'Entries';

        // removing any index that arent an integer
        Object.keys(processingData).filter(key => isNaN(Number(key))).forEach(key => delete processingData[key]);

        // we can also sort the items in the dates by Field Parameter primaryCalendarField instead of created_at

        angular.forEach(processingData, function (value, index) {
            let dateValueMomentObject = moment(value[data.primaryCalendarField], calendarPrimaryFieldFormat);
            let createdBy = moment(value[data.primaryCalendarField]).format('DD-MM-YY');

            if(data.primaryCalendarField != 'created_at') {
                createdBy = dateValueMomentObject.format('DD-MM-YY');
            }

            if (entryValues[createdBy] != null) {
                entryValues[createdBy].count++;
                entryValues[createdBy] = {
                    start : entryValues[createdBy].start,
                    end   : entryValues[createdBy].end,
                    count : entryValues[createdBy].count,
                    title : `${entryValues[createdBy].count} ${pluralText}`
                };
            } else {
                let startTime = dateValueMomentObject.format('YYYY-MM-DD HH:mm:ss');
                let finishingTime = dateValueMomentObject.format('YYYY-MM-DD HH:mm:ss');
                entryValues[createdBy] = {
                    start : startTime,
                    end   : finishingTime,
                    count : 1,
                    title : `1 ${singularText}`
                };
            }
        });

        // Removing the count index
        Object.keys(entryValues).filter(key => key).forEach(function (key, value) {delete entryValues[key].count});

        // returning the array with indexes instead of the date as array index
        return Object.values(entryValues);

    }

    return factory;

}
