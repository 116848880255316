import * as angular from 'angular';

"use strict";

angular.module('CalendarModule')
       .service('CalendarService', CalendarService)
       .config(CalendarServiceConfig);

CalendarService.$inject = ['$http', 'Configuration'];

/**
 * Service constructor.
 * @constructor
 */
function CalendarService($http, Configuration) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * This will filter the scanstation data by click on the calendar.
     *
     * @param scanStationID
     * @param dataFilteringData
     * @param fieldIdOrFieldSlug
     */
    service.filterScanStationData = (scanStationID, dataFilteringData, fieldIdOrFieldSlug) => {

        let apiBase = apiUrl + 'get-data-for-data-filtering';
        let config = {
            params: {
                scanStationID : scanStationID,
                dataFilteringData: dataFilteringData,
                fieldIdOrFieldSlug: fieldIdOrFieldSlug
            }
        };

        return $http.get(apiBase, config);
    }
}


function CalendarServiceConfig ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
};