import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('barcodeGeneratorField', barcodeGeneratorField);

/**
 * Service constructor.
 * @constructor
 */
function barcodeGeneratorField() {
    let service = this;


    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'showInputField': {
                    'validation' : {
                        'required': false,
                    }
                },
                'codeType': {
                    'validation' : {
                        'required': false,
                    }
                },
                'height': {
                    'validation' : {
                        'required': false,
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}