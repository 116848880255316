import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('createUserPopUpField', CreateUserPopUpField);

/**
 * Service constructor.
 * @constructor
 */
function CreateUserPopUpField() {
    let service = this;

    /**
     * This will load the initial parameters.
     */
    service.getInitialParams = function () {
        return {
            'buttonText': {
                'validation' : {
                    'required': false,
                }
            },
            'buttonColour': {
                'validation' : {
                    'required': false,
                }
            },
            'usernameField': {
                'validation' : {
                    'required': false,
                }
            },
            'fullNameField': {
                'validation' : {
                    'required': false,
                }
            },
            'profileFilterField': {
                'validation' : {
                    'required': false,
                }
            },
            'submitFormAfterUserAdded': {
                'validation': {
                    'required': false,
                }
            }
        };
    }

}