import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('fieldRuleFactory', fieldRuleFactory);


fieldRuleFactory.$inject = [];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function fieldRuleFactory() {
    let factory = this;

    /**
     * Method responsible for building field pairs for specific rules
     * @param paramNumber
     * @param selectedLocalField
     * @param selectedRemoteField
     * @param ruleParameters
     * @returns {*}
     */
    factory.buildMatchingPairs = async function (paramNumber, selectedLocalField, selectedRemoteField, ruleParameters) {

        let index = Object.keys(ruleParameters).length;

        ruleParameters[index] = {
                localFieldId: selectedLocalField.id,
                remoteFieldId: selectedRemoteField.id,
                localFieldName: selectedLocalField.field_name,
                remoteFieldName: selectedRemoteField.field_name,
            }

        return ruleParameters;
    }

    /**
     * Method responsible for building field pairs for specific rules
     * @param newRuleID
     * @param newruleParameters

     * @returns {*}
     */

    factory.clearUnwantedRuleParameters = async function (newRuleID, newruleParameters) {

        if(newRuleID == 'populate_another_local_field_with_data_from_elsewhere' && typeof newruleParameters.param6 != 'undefined' ) {
            angular.forEach(newruleParameters.param6, function(pair, key) {
                delete pair.localFieldName;
                delete pair.remoteFieldName;
            });
        }

        if(newRuleID == 'must_have_matching_equivalent_data_in_another_station' && typeof newruleParameters.param3 != 'undefined' ) {
            angular.forEach(newruleParameters.param3, function(pair, key) {
                delete pair.localFieldName;
                delete pair.remoteFieldName;
            });
        }

        return newruleParameters;
    }

    return factory;
}

