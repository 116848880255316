import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('BluetoothFactory', BluetoothFactory);


BluetoothFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function BluetoothFactory(fieldService, Configuration, $http) {
    let factory = this;

    /**
     * This function processes the bluetooth data based on specific characteristics and a defined way they behave
     * @param event
     * @param field
     */
    factory.processBluetoothData = async function(event, characteristicUUID) {

        let result = false;

        // this list contains the list of services and characteristics https://gist.github.com/sam016/4abe921b5a9ee27f67b3686910293026

        switch(characteristicUUID) {

            case '00002a1c-0000-1000-8000-00805f9b34fb':
                result = await factory.processTemperatureMeasurement(event);
                break;
            default:
        }

        return result;
    }

    factory.processTemperatureMeasurement = async function(event) {

        let LRLevelArray = new Uint8Array(4);

        LRLevelArray[0] = event.target.value.getInt8(1);
        LRLevelArray[1] = event.target.value.getInt8(2);
        LRLevelArray[3] = event.target.value.getInt8(3);
        LRLevelArray[4] = event.target.value.getInt8(4);

        let LRLevel = LRLevelArray.buffer;

        let uint8View = new Int32Array(LRLevel);

        return (uint8View[0] / 10);
    }


    return factory;
}

