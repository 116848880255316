import * as angular from 'angular';
import { isArray } from "util";

"use strict";

angular.module('FieldModule')
    .controller('FieldRuleController', FieldRuleController);

FieldRuleController.$inject = ['$scope', 'fieldRuleFactory'];

// Controller Constructor
function FieldRuleController($scope, fieldRuleFactory) {
    // Creating the view model reference
    let vm = this;
    vm.scanStation = '';
    vm.main = '';
    vm.factory = ''

    vm.$onInit = function () {
        try {
            // Assigning the scan station object and main
            $scope.scanStation = vm.scanStation;
            $scope.main = vm.main;
            $scope.loadParent = vm.loadParent;
            vm.factory = fieldRuleFactory;

            // this will register the this controller into the parent controller component
            vm.registerParent({childRef: vm});

            // making sure that we can get data ruleDataEntryMedium
            vm.buildScanStationRuleEntries();


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be running before any field Rule that are required to
     * have the scanStationFieldsForRule in field.html.
     */
    vm.loadStationFieldsForRule = async () => {

        // This will load only if contains in the html a field has: load-parent="'param1'"
        if (vm.loadParent){
            vm.scanStation.getJobScanStations(null);
            await vm.scanStation.getStationStationFieldsForRule(vm.scanStation.newRule.rules_parameters[vm.loadParent]);
            $scope.$apply();
        }
    }


    /**
     * Method that will check if the scan station new rule will be clean before
     * add any new rule.
     */
    vm.buildScanStationRuleEntries = function () {
        try {

            if ($scope.scanStation !== undefined) {

                // New way to store data
                if ($scope.scanStation.ruleDataEntryMedium !== undefined) {
                    Object.entries($scope.scanStation.ruleDataEntryMedium)
                        .map(([key, value]) => [key, value = undefined]);
                }

            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be building the match pair.
     *
     * @param paramNumber
     * @param selectedLocalField
     * @param selectedRemoteField
     */
    vm.buildMatchingPairs = async function (paramNumber, selectedLocalField, selectedRemoteField) {

        if (typeof vm.scanStation.newRule.rules_parameters[paramNumber] == 'undefined') {
            vm.scanStation.newRule.rules_parameters[paramNumber] = [];
        }
        vm.scanStation.newRule.rules_parameters[paramNumber] = await fieldRuleFactory.buildMatchingPairs(paramNumber, selectedLocalField, selectedRemoteField, vm.scanStation.newRule.rules_parameters[paramNumber]);

    }

    /**
     * This will remove the matching pair from the Field Matching Combinations
     * @param paramNumber
     * @param pair
     * @param $index
     */
    vm.removeMatchingPair = function (paramNumber, pair, $index) {
        // Double check that is an array element to be removed
        if(Array.isArray(vm.scanStation.newRule.rules_parameters[paramNumber])){
            vm.scanStation.newRule.rules_parameters[paramNumber].splice($index, 1);
        }
    }

    /**
     * This will return the rules parameters from the newRule (object that is mainly to add/edit).
     *
     * @param param
     */
    vm.loadRulesParameters = (param) => {
        return vm.scanStation.newRule.rules_parameters[param]
    }

}

export default FieldRuleController;