import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('fileBuilderField', fileBuilderField);

/**
 * Service constructor.
 * @constructor
 */
function fileBuilderField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'filename': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please type the file name including extension that the integrator will detect.',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'buttonText': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please type the button name.',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'buttonColour': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please select the color.',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'fontAwesomeIcon': {
                    'validation': {
                        'required': false,
                    }
                },
                'path': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please type the source path',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'dropdownFromStationLookUp': {
                    'subParameters': {
                        'stationId': {
                            'validation' : {
                                'required': true,
                                'validationResponse': 'Please choose a bloc to look up.',
                                'requiredType': 'stringMustNotBeEmpty'
                            }
                        }
                    },
                    'validation' : {
                        'required': true,
                        'validationResponse': 'Please select a bloc and a field to look up',
                        'requiredType': 'listMustHaveOne'
                    }
                },
                'localFieldMatchingFieldPairsArrayToCheckAgainst': {
                    'validation' : {
                        'required': false,
                    }
                },
                'exportedFieldsArray': {
                    'validation' : {
                        'required': false,
                    }
                },
                'filebuilder': {
                    'validation' : {
                        'required': false,
                    }
                },
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}