import * as angular from 'angular';
import DetectInactivityController from "./controllers/detect-inactivity.controller";
import StandardComponent from 'components/standards/components/StandardComponent';

"use strict";

class DetectInactivityComponent extends StandardComponent {

    // This will enable the debug mode for this component
    public debug = false;
    public moduleFolder = 'components/detect-inactivity';

    /**
     * Please make sure that each function contain correct
     * documentation.
     */

    public bindings = { }

    // This will set whatwill be the controller of this component
    public controller: any = DetectInactivityController;

    // This will set an alias
    public controllerAs: string = 'di';
}

angular.module('DetectInactivityModule').component('detectInactivity', <StandardComponent> new DetectInactivityComponent);