import * as angular from 'angular';

"use strict";

export default class PathFilter {

    public static id = "publicpath";

    public static Factory() {

        let factoryFunction = () => {
            return (data: string) => {
                const regex = RegExp('(?:.*\\/)(?<path>databases.*)', 'g');
                const result = regex.exec(data);
                return result[1];
            };
        };

        factoryFunction.$inject = [];

        return factoryFunction;
    }
}

angular.module('ExportModule')
    .filter(PathFilter.id, PathFilter.Factory());
