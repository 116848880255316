import * as angular from 'angular';

"use strict";

angular.module('DownloadModule')
       .factory('DownloadFactory', DownloadFactory)
;

DownloadFactory.$inject = [];

/**
 * Object that will create a Page Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @constructor
 */
function DownloadFactory() {

    let factory = this;

    // semaphore for the block
    factory.showTemplateBlock = null;

    // factory variables
    factory.templateFileList = null;
    factory.downloadUrl = null;
    factory.filename = 'mockup.docx';

    /**
     * Main docx filename
     */
    factory.getFilename = () => {
        return factory.filename;
    }

    /**
     * This will close the template block.
     */
    factory.closeTemplateBlock =  () => {
        factory.showTemplateBlock = false;
    }

    /**
     * This will be the toggle action for the main red button, so if
     * one click is done it will open, next time will be getting
     * the opposite value, closing the block.
     */
    factory.toggleTemplateBlock =  () => {
        factory.showTemplateBlock = factory.showTemplateBlock === null ? true : !factory.showTemplateBlock;
    }

    factory.buildMailToAddresses = (dataSource, scanStationFields) => {
        let emailAddress = [];
        angular.forEach(scanStationFields, function(field) {
            if(field.emailAddressForTemplateExporting == true) {
                emailAddress.push(dataSource[field.field_slug]);
            }
        });

        return emailAddress.join(',');
    }


    return factory;

}

export default DownloadFactory;
