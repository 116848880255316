import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .controller('BarcodeGeneratorController', BarcodeGeneratorController);

// Injection of each class
BarcodeGeneratorController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'BarcodeGeneratorFactory'];

// Controller Constructor
function BarcodeGeneratorController($rootScope, $scope, ScopeAndLocal, BarcodeGeneratorFactory) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');

        } catch (e) {
            console.log(e);
        }
    }


    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    /**
     * This function will generate the barcode from Laravel based on the string
     */

    vm.generateBarcodeFromString = async function(e, string, field) {

        if(e == 'no-event' || e.keyCode == 13) {

            if(!string.includes('data:image/png;base64')) {
                if(vm.barcodeText == null) {
                    vm.barcodeText = [];
                }
                vm.barcodeText[field.field_slug] = string;
                let data = await BarcodeGeneratorFactory.generateBarcodeFromString(string, field.codeType, field.height);
                vm.scanStation.scanStationObj.dataToAdd[field.field_slug] = data.data.data;
                $scope.$apply();
            }
        }

    }
}

export default BarcodeGeneratorController;