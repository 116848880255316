import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('fieldRuleValidationFactory', fieldRuleValidationFactory);


fieldRuleValidationFactory.$inject = [];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function fieldRuleValidationFactory() {
    let factory = this;

    /**
     * This will check if the parameter contain the required == true
     * @param parameter
     */
    factory.isRequired = (parameter) => {
        return parameter.required == true;
    }

    /**
     * This will be checking if it is null or empty and will return
     * true if we can find a nullable or empty value, otherwise
     * will return false.
     *
     * @param parameter
     * @param key
     */
    factory.isNullOrEmpty = (parameter, key) => {
        return parameter == null || parameter[key] === '' ? true : false;
    }

    /**
     * This will be checking if the value is null and also different from 0
     * this is more like an assurance that if null will be empty as it also isnt
     * 0, so allowing the 0 values in.
     *
     * if those
     * @param parameter
     * @param key
     */
    factory.valueIsNullOrDifferentOfZero = (parameter, key) => {
        return parameter[key] == null && parameter[key] !== 0 ? true : false;
    }

    /**
     * Method responsible for validating rules
     * @param rule
     * @param newRuleParameters
     * @returns string
     */
    factory.validateRule = async function (rule, newRuleParameters) {

        let ruleResponse = [];
        if(rule == null) {
            return 'Please select a rule';
        }
        angular.forEach(rule.data_entry_medium, function(parameter, parameterKey) {
            if(factory.isRequired(parameter)){
                if(factory.isNullOrEmpty(newRuleParameters, parameterKey) ||  factory.valueIsNullOrDifferentOfZero(newRuleParameters, parameterKey)){
                    ruleResponse.push(parameter.validationResponse);
                }
            }
        });

        if(ruleResponse.length == 0) {
            return true;
        } else {
            return ruleResponse;
        }

    }


    return factory;
}

