import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('BlocworxGatewayFactory', BlocworxGatewayFactory);


BlocworxGatewayFactory.$inject = ['fieldService', 'Configuration', '$http', 'DateService', 'scanStation', '$rootScope'];


/**
 * Object that will create a Blocworx Gateway Factory, all methods of factory
 * will be available from outside.
 */
function BlocworxGatewayFactory(fieldService, Configuration, $http, DateService, scanStation, $rootScope) {
    let factory = this;
    
    /**
     * This function is responsible for calling the Blocworx Gateway (an API)
     **/

    factory.triggerActions = async (fieldId, dataToAdd, filesToAdd) => {
        let fd = new FormData();

        // This was set for all date fields being well formated before save the data
        dataToAdd = DateService.updateDatesInArray(dataToAdd);

        dataToAdd = scanStation.removeImagePreview(dataToAdd);

        angular.forEach(filesToAdd, function (file, index) {
            fd.append('uploaded-file-' + index, file);
        });

        fd.append('dataToAdd', JSON.stringify(dataToAdd));
        fd.append('fieldId', fieldId);

        let apiBase = Configuration.getApiUrl() + 'trigger-blocworx-gateway-calls';

        let data = await $http.post(apiBase, fd, {transformRequest: angular.identity, headers: {'Content-Type': undefined}});

        return data;
    }
    
     /**
     * This function is for handling the broadcast and triggering rules, we are using a service so we can handle async await from ScanStationCtrl
     **/

    factory.triggerPostBlocworxGatewayCall = async (fieldId) => {
        console.log('before');
        await factory.delay(5000);
        console.log('after');
        return true;
    }
    
    /*
    * Create an array of hours in a day
    * @returns {Array}
    */
    factory.createHourArray = function() {
        let hours = [];
        for (let i = 0; i < 24; i++) {
            if (i < 10) {
                hours.push({"value": i, "text": `0${i}:00`});
            } else {
                hours.push({"value": i, "text": `${i}:00`});
            }
        }
        return hours;
    }

    /*
    * Create an array of days in a month
    * @returns {Array}
    */
    factory.createDayArray = function() {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            if (i < 10) {
                days.push({"value": `0${i}`, "text": `0${i}`});
            } else {
                days.push({"value": i, "text": `${i}`});
            }
        }
        days.push({"value": "last", "text": "Last day of month"});
        return days;
    }

    /**
     * Method responsible for validating fields
     * @param localFieldId
     * @param condition
     * @param valueToCompare
     * @returns void or error
     */
    factory.validate = function (localFieldId: string, condition: string, valueToCompare: string): void | Error {

        if (!localFieldId) {
            throw new Error('Local Field to check is not defined or empty');
        }

        if (!condition) {
            throw new Error('Condition is not defined or empty');
        }

        if (!valueToCompare) {
            throw new Error('Value to compare is not defined or empty');
        }

        if (condition !== 'equal' && condition !== 'notEqual' && !Number(valueToCompare)) {
            throw new Error('Strings must use condition Equal');
        }
    }

    /**
     * Method responsible for adding conditions
     * @param conditions array of conditions
     * @param localFieldId id of the local field
     * @param condition condition to be added
     * @param valueToCompare value to compare
     * @returns conditions array with new condition
     */
    factory.add = function (conditions: any, localFieldId: string, condition: string, valueToCompare: string): any {

        localFieldId = JSON.parse(localFieldId);

        conditions[Object.keys(conditions).length] = {
            localFieldId,
            condition,
            valueToCompare
        };
        return conditions;
    }

    /**
     * Method responsible for removing conditions
     * @param conditions array of conditions
     * @param index index of condition to be removed
     * @returns conditions array without condition
     */
    factory.remove = function (conditions: any, index: number): any {

        delete conditions[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = conditions;
        conditions = {};
        let i = 0;
        for (let key in fieldListForLoop) {
            conditions[i] = fieldListForLoop[key];
            i++;
        }
        return conditions;
    }


    return factory;
}

