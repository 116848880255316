import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import BarcodeGeneratorController from "./controllers/barcodeGenerator.controller";

"use strict";

class BarcodeGeneratorComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/barcodeGenerator';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        field: '=',
        row: '=',
        editScanStation: '@',
        data: '='
    }

    // This will set what will be the controller of this component
    public controller : any = BarcodeGeneratorController;

    // This will set an alias
    public controllerAs: string = 'barcodeGenerator';
}

angular.module('FieldModule').component('barcodeGenerator', <StandardComponent> new BarcodeGeneratorComponent);