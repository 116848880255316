import * as angular from 'angular';

"use strict";

export default class ProcessedFilter {

    public static id = "processed";

    public static Factory() {

        let factoryFunction = () => {
            return (data: number) => {
                return data === 1 ? 'Yes' : 'Not Processed';
            };
        };

        factoryFunction.$inject = [];

        return factoryFunction;
    }
}

angular.module('ExportModule')
    .filter(ProcessedFilter.id, ProcessedFilter.Factory());
