import * as angular from 'angular';

"use strict";

angular
    .module('FieldModule')
    .factory('CreateUserPopUpFieldFactory', linkFieldFactory);


linkFieldFactory.$inject = [];


/**
 * Object that will create a CreateUserPopUp Field Factory, all methods of factory
 * will be available from outside.
 */
function linkFieldFactory() {
    let factory = this;

    /**
     * This will be responsible for building the json string that gets added to the end of the link in the results/records
     *
     * @param field
     * @param dataToAdd
     */
    factory.buildManageUsersUrl = (blocId, field, dataToAdd, scanStationFieldIdsToField) => {

        let url = '/admin/manage-users';

        url += '?fromBlocId=' + blocId;
        
        if(field.usernameField != null && scanStationFieldIdsToField[field.usernameField] != null && dataToAdd[scanStationFieldIdsToField[field.usernameField].field_slug] != null ) {
            url += '&username=' + dataToAdd[scanStationFieldIdsToField[field.usernameField].field_slug];
        }

        if(field.fullNameField != null && scanStationFieldIdsToField[field.fullNameField] != null && dataToAdd[scanStationFieldIdsToField[field.fullNameField].field_slug] != null ) {
            url += '&name=' + dataToAdd[scanStationFieldIdsToField[field.fullNameField].field_slug];
        }

        if(field.profileFilterField != null && scanStationFieldIdsToField[field.profileFilterField] != null && dataToAdd[scanStationFieldIdsToField[field.profileFilterField].field_slug] != null ) {
            url += '&profileFilter=' + dataToAdd[scanStationFieldIdsToField[field.profileFilterField].field_slug];
        }


        return url;

    }


    return factory;
}



