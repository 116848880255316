import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import LinkController from "./controllers/link.controller";

"use strict";

class LinkData extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/linkData';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        fc: '<',
        field: '<',
        row: '<',
    }


    // This will set what will be the controller of this component
    public controller : any = LinkController;

    // This will set an alias
    public controllerAs: string = 'li';
}

angular.module('FieldModule').component('linkData', <StandardComponent> new LinkData);