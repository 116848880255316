import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('FileBuilderFactory', FileBuilderFactory);

FileBuilderFactory.$inject = ['fieldService', 'Configuration', '$http', '$rootScope'];

/**
 * Service constructor.
 * @constructor
 */
function FileBuilderFactory(fieldService, Configuration, $http, $rootScope) {
    let factory = this;

    /**
     * This will be responsible to send a file.
     *
     * @param jobID
     * @param stationID
     * @param files
     * @param fieldId
     * @param allFieldsData
     */
    factory.sendRequest = async (jobID, stationID, files, fieldId, allFieldsData) => {
        let fd = new FormData();
        fd.append('jobID', jobID);
        fd.append('stationID', stationID);
        fd.append('allFieldsData', JSON.stringify(allFieldsData));
        fd.append('file', files);
        fd.append('fieldId' , fieldId);
        let apiBase = Configuration.getApiUrl() + 'file-builder-send-request';
        let data = await $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
        return data;
    }

    /**
     * This is the action of click on the file builder button
     * this must be doing the things to process
     * the action.
     *
     * @param jobID
     * @param stationID
     * @param fieldId
     */
    factory.buildFile = async (formFields, fieldId) => {
        try
        {
            let apiBase = Configuration.getApiUrl() + 'file-builder-build-file';
            let fd = new FormData();
            fd.append('formFields', JSON.stringify(formFields));
            fd.append('fieldId' , fieldId); // this is to take the field things

            let data = await $http.post(apiBase, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
            return data;
        } catch ( exception ) {
            console.log(exception)
            throw exception;
        }

    }

    /**
     * This will be responsible to save this file on the file_builder
     * table.
     *
     * @param formFields
     * @param fieldId
     */
    factory.saveFile = async ({ dataToAdd, jobID, scanStationDataID, scanStationID, formFields }) => {
        try
        {
            let apiBase = Configuration.getApiUrl() + 'file-builder-save-file';
            let fd = new FormData();
            fd.append('formFields', JSON.stringify(formFields));
            fd.append('dataToAdd', JSON.stringify(dataToAdd));
            fd.append('scanStationID' , scanStationID);
            fd.append('scanStationDataID' , scanStationDataID);
            fd.append('jobID' , jobID);
            let data = await $http.post(apiBase, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
            return data;

        } catch ( exception ) {
            console.log(exception)
            throw exception;
        }

    }

    /**
     * This will validate the file builder field requirements.
     *
     * @param formFields
     * @param dataToAdd
     * @param jobID
     * @param scanStationID
     */
    factory.fileBuilderValidate = async ({ formFields, dataToAdd, jobID, scanStationID }) => {
        try
        {
            let apiBase = Configuration.getApiUrl() + 'file-builder-validate';
            let fd = new FormData();
            fd.append('dataToAdd', JSON.stringify(dataToAdd));
            fd.append('formFields', JSON.stringify(formFields));
            fd.append('scanStationID' , scanStationID);
            fd.append('jobID' , jobID);
            let data = await $http.post(apiBase, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
            return data;

        } catch ( exception ) {
            throw exception;
        }
    }

    /**
     * This will be responsible to load the scan station data for a
     * file_builder field.
     *
     * @param field
     * @param row
     */
    factory.loadScanStationData = async (field, row) => {
        try
        {
            let apiBase = Configuration.getApiUrl() + 'file-builder-load-scan-station-data';
            let fd = new FormData();

            fd.append('field', JSON.stringify(field));
            fd.append('row', JSON.stringify(row));

            let data = await $http.post(apiBase, fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });

            if(data.status != undefined && data.status==200){
                return data.data.response;
            }

            return data.data.response;

        } catch ( exception ) {
            throw exception;
        }
    }

    /**
     * Download of a file builder file.
     *
     * @param file_path
     */
    factory.downloadFile = async (file_path) => {
        try
        {
            let apiBase = Configuration.getApiUrl() + 'file-builder-download-file';
            let config = {
                responseType: 'blob',
                params: {
                    file_path: file_path,
                }
            };
            return $http.get(apiBase, config);

        } catch ( exception ) {
            throw exception;
        }
    }
}