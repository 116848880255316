import * as angular from 'angular';
import DownloadController from "./controllers/download.controller";


"use strict";

let DownloadComponent =  {
    templateUrl: 'components/download/template/download.html',
    controller: DownloadController,
    controllerAs: 'd',
    bindings: {
        main: '=',
        scanStation: '=',
        data: '=',
        exportFromData: '=',
        rowId: '=',
        rowKey: '=',
        psc: '='
    }
}

angular.module('DownloadModule')
       .component('download', DownloadComponent);
