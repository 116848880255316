import * as angular from 'angular';
import { Calendar, sliceEvents, createPlugin } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import * as $ from "jquery";

import * as moment  from "moment";

"use strict";

angular.module('CalendarModule')
       .controller('CalendarController', CalendarController);

// Injection of each class
CalendarController.$inject = ['CalendarFactory', '$scope', 'CalendarService'];

// Controller Constructor
function CalendarController(CalendarFactory, $scope, CalendarService) {

    // Creating the view model reference
    let vm = this;
    vm.calendarData = {};

    /**
     * This will be responsible to configure a new calendar
     * and make available to load with correct configuration set.
     * @param calendarEl
     */
    vm._loadCalendar = (calendarEl) => {
        try
        {
            // getting the calendar object
            vm.calendar = new Calendar(calendarEl, {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                selectable: true,
                initialView: 'dayGridMonth',
                themeSystem: 'bootstrap',
                events: vm.calendarData,
                displayEventTime: true,
                headerToolbar: {
                    left: 'dayGridMonth,dayGridWeek',
                    center: 'title',
                    right: 'prevYear,prev,next,nextYear'
                },
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false
                },
                views: {
                    dayGridWeek: {
                        dayMaxEventRows: 20,
                        dayHeaderFormat: function(date){
                            return moment(date.date).format('DD/MM');
                        },

                    },
                    monthGrid: {
                        eventLimit: false
                    }
                },
                eventClick: async (info) => await vm.calendarHourClick(info),
                dateClick: async (info) => await vm.calendarDateClick(info),
                eventContent: (arg) => vm.calendarEventContent(arg),

            });

            // Rendering the calendar object after mounting it
            vm.calendar.render();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will change the html to be printed
     *
     * @param info
     */
    vm.calendarEventContent = (eventInfo) => {

        let p = document.createElement('p')
        // p.innerHTML = `<b>${eventInfo.timeText}</b><i>${eventInfo.event.title}</i>`;
        p.innerHTML = `${eventInfo.event.title}`;
        let arrayOfDomNodes = [ p ]
        return { domNodes: arrayOfDomNodes }

    }

    /**
     * This will trigger when an hour is selected.
     *
     * @param info
     */
    vm.calendarHourClick = async (info) => {

        info.jsEvent.preventDefault();

        let parentElement = info.el.parentElement.parentElement.parentElement.parentElement;
        let fullDate = parentElement.dataset.date

        let day = moment(fullDate).format('YY-MM-DD');
        let hour = '00:00:00';

        // building the data filtering object
        let dataFilteringData = {
            'stationID': vm.scanStation.id,
            'startDate': `${day} ${hour}`,
            'endDate': `${day} 23:59:59`
        }

        let data = await CalendarService.filterScanStationData(vm.scanStation.id, dataFilteringData, 'fieldSlugs');
        vm.scanStation.scanStationObj.liveData = data.data.data;
        $scope.$apply();

    }

    /**
     * This will trigger when an day is selected.
     *
     * @param info
     */
    vm.calendarDateClick = async (info) => {
        try
        {
            let day,hour = '';

            // Building date and hour filter
            if (info.dateStr !== undefined){
                day = info.dateStr;
                hour = '00:00:00';
            } else {
                let dateSplit = info.dateStr.split(/[T,+]/);
                day = dateSplit[0];
                hour = dateSplit[1];
            }

            // building the data filtering object
            let dataFilteringData = {
                'stationID': vm.scanStation.id,
                'startDate': `${day} ${hour}`,
                'endDate': `${day} 23:59:59`
            }

            // update of the scan station data
            let data = await CalendarService.filterScanStationData(vm.scanStation.id, dataFilteringData, 'fieldSlugs');
            vm.scanStation.scanStationObj.liveData = data.data.data;
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Main constructor of this controller class.
     */
    vm.$onInit = async () => {
        try
        {
            // loading calendar data
            $scope.scanStation = vm.scanStation;
            vm.calendarData = await vm.getCalendarData();
            vm._loadCalendar(document.getElementById('full-calendar'));

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will take the current scan station object and using the
     * CalendarFactory it will be loading the data for this calendar
     * that will be returned an object with data for our calendar.
     *
     */
    vm.getCalendarData = async function () {
        try
        {
            let data = await CalendarFactory.getCalendarData(vm.scanStation);
            return CalendarFactory.buildingCalendarEvents(data.data, vm.scanStation.calendarPrimaryFieldFormat);

        } catch (e) {
            console.log(e);
        }
    };

}


export default CalendarController;