import * as angular from 'angular';

"use strict";

angular.module('DatetimeModule')
       .controller('DatetimeController', DatetimeController);

DatetimeController.$inject = ['$scope', 'ScopeAndLocal'];

// Controller Constructor
function DatetimeController($scope, ScopeAndLocal) {

    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'date-only');
            ScopeAndLocal.startVariable(vm, $scope, 'model');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Method that will check if we only need to print
     * an extra parameter date-only
     */
    vm.checkDateOnly = () => {

        if(vm.dateOnly===undefined || vm.dateOnly === false){
            return false
        }
        return true
    }

}

export default DatetimeController;