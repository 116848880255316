import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import BlocworxGatewayController from "./controllers/blocworx-gateway.controller";

"use strict";

class BlocworxGatewayComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/blocworxGateway';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        field: '=',
        row: '=',
        editScanStation: '@',
        data: '='
    } 

    // This will set what will be the controller of this component
    public controller : any = BlocworxGatewayController;

    // This will set an alias
    public controllerAs: string = 'blocworxGateway';
}

angular.module('FieldModule').component('blocworxGateway', <StandardComponent> new BlocworxGatewayComponent);