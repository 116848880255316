import * as angular from 'angular';
import Exceptions from '../Exceptions';

"use strict";

class EmptyFieldException extends Exceptions
{
    public name = 'EmptyFieldException';

    constructor(message, type) {

        super(message, type);

        this.name = message;

        !message ? message = "You are missing to type a field."
            : message = `You must type: ${message}`;

        super(message, type);
    }
}

angular.module('ExceptionsModule')
       .service('EmptyFieldExceptionFactory', EmptyFieldExceptionFactory);


EmptyFieldExceptionFactory.$inject = ['$mdDialog'];

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @returns {Configuration}
 * @constructor
 */
function EmptyFieldExceptionFactory($mdDialog)
{
    let exceptionFactory = this;

    exceptionFactory.throwException  = (message) => {
        exceptionFactory.exception = new EmptyFieldException(message, 'EmptyFieldException');
        throw exceptionFactory.exception;
    }

    exceptionFactory.showAlert  = () => {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title('Please enter a value to search')
                .textContent(exceptionFactory.exception.messag)
                .ariaLabel('Empty Field')
                .ok('Ok')
        );
    }

    return exceptionFactory;
}
