import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import BluetoothController from "./controllers/bluetooth.controller";

"use strict";

class BluetoothComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/bluetooth';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        scanStation: '=',
        fc: '=',
        field: '=',
        row: '=',
        editScanStation: '@',
        data: '='
    }

    // This will set what will be the controller of this component
    public controller : any = BluetoothController;

    // This will set an alias
    public controllerAs: string = 'bluetooth';
}

angular.module('FieldModule').component('bluetooth', <StandardComponent> new BluetoothComponent);