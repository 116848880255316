import * as angular from 'angular';
import Exceptions from '../Exceptions';

"use strict";

class InvalidEmailException extends Exceptions {
    constructor(message, type) {
        !message ? message = "Sorry Invalid email!"
            : message = `Sorry, Invalid email: ${message}`;
        super(message, type);
    }
}

// Declaration of the service
angular.module('ExceptionsModule')
       .factory('InvalidEmailExceptionFactory', InvalidEmailExceptionFactory);

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @returns {Configuration}
 * @constructor
 */
function InvalidEmailExceptionFactory() {

    return {
        throwException: function (message) {
            throw new InvalidEmailException(message, 'InvalidEmailException');
        }
    }

}
