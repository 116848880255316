import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import ExportedFieldsController from "./controllers/exportedFields.controller";

"use strict";

class ExportedFields extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/exportedFields';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        fc: '<',
        row: '<',
        processField : '<',
    }


    // This will set what will be the controller of this component
    public controller : any = ExportedFieldsController;

    // This will set an alias
    public controllerAs: string = 'efc';
}

angular.module('FieldModule').component('exportedFields', <StandardComponent> new ExportedFields);