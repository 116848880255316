import * as angular from 'angular';
import CalendarController from "./controllers/calendar.controller";

"use strict";

let scanStationEditPageComponent =  {
    templateUrl: 'components/calendar/template/calendar.html',
    controller: CalendarController,
    controllerAs: 'c',
    bindings: {
        scanStation: '=',
    }
}

angular.module('CalendarModule')
       .component('fullCalendar', scanStationEditPageComponent);
