import * as angular from 'angular';
import Exceptions from '../Exceptions';

"use strict";

class LoginException extends Exceptions
{
    constructor(message, type) {
        super(message, type);
    }
}

angular.module('ExceptionsModule')
       .factory('LoginExceptions', LoginExceptions);

LoginExceptions.$inject = ['Auth'];

function LoginExceptions(Auth)
{
    return {
        throwDoesNotExistUserException : function () {
            throw new LoginException(Auth.doesNotExistUserMessage, 'does_not_exist_user');
        },
        throwInvalidCredentialsException : function () {
            throw new LoginException(Auth.incorrectUsernameMessage, 'invalid_credentials');
        },
        throwWrong2FACodeException : function () {
            throw new LoginException(Auth.wrong2FACodeMessage, 'wrong_2_factor_authentication');
        }
    }
}
