import * as angular from 'angular';
import Exceptions from '../Exceptions';

"use strict";

class MissingScanStationFieldsException extends Exceptions {
    constructor(message, type) {
        !message ? message = "You need to receive data from Scan Station Fields, its missing this data"
            : message = `Missing the scan station fields: ${message}`;
        super(message, type);
    }
}

angular.module('ExceptionsModule')
       .factory('MissingScanStationFieldsExceptionFactory', MissingScanStationFieldsExceptionFactory);

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @returns {Configuration}
 * @constructor
 */
function MissingScanStationFieldsExceptionFactory() {

    let service = this;

    return {
        throwException: function (message) {
            throw new MissingScanStationFieldsException(message, 'MissingScanStationFieldsException');
        }
    }

}
