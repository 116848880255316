import * as angular from 'angular';

"use strict";

angular.module('DetectInactivityModule')
    .controller('DetectInactivityController', DetectInactivityController);

// Injection of each class
DetectInactivityController.$inject = ['$scope', '$window', '$rootScope'];

// Controller Constructor
function DetectInactivityController($scope, $window, $rootScope) {

    let vm = this;
    vm.events = ['click', 'mousemove', 'mousedown', 'keyup', 'keypress', 'scroll'];

    vm.warningTimer = null;
    vm.logoutTimer = null;
    vm.showWarning = false;
    vm.timer = null

    const broadcastChannel = new BroadcastChannel('detected_activity');

    vm.$onInit = function () {

        try {

            // this is stored at: admin/app-settings
            let appSettingList = JSON.parse(localStorage.getItem('appSettings'));

            if (appSettingList.data !== null) {
                angular.forEach(appSettingList.data, function (settingEntry) {
                    if (settingEntry.setting_key == 'inactivity_timeout') {
                        vm.timer = settingEntry.setting_value;
                    }
                });
            }

            if (vm.timer !== null) {
                vm.createEventListeners();
                vm.setTimers();
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Removal of all event listeners.
     */
    vm.$onDestroy = function () {
        vm.events.forEach(function (event) {
            $window.removeEventListener(event, function () {
                vm.resetTimer();
            });
        });
    }

    /**
     * Function that creates an event listener that
     * on each event triggered we do:
     *  1 - reset the timer (so we can start again the setTimeout last parameter)
     *  2 - broadcast thought all other browser tabs.
     */
    vm.createEventListeners = function () {
        vm.events.forEach(function (event) {
            $window.addEventListener(event, function () {
                vm.resetTimer();
                broadcastChannel.postMessage('detected_activity');
            });
        });
    }

    /**
     * Function that do the broadcast to all other browsers tabs.
     */
    broadcastChannel.onmessage = function () {
        vm.showWarning = false;
        vm.resetTimer();
    }

    /**
     * Function that does clear the timeout references that we get from the
     * function vm.setTimers(). Afterwards calls it again so we can set again the
     * timeout.
     */
    vm.resetTimer = function () {
        clearTimeout(vm.warningTimer);
        clearTimeout(vm.logoutTimer);
        vm.setTimers();
    }

    /**
     * Just a quick way to transform from minutes to ms.
     * @param time
     */
    vm.toMinutes = (time) => {
        return time * 60 * 1000;
    }

    /**
     * Function that set the timeout for each action, warning and logout, warning
     * must be shown half minute before the action of logout.
     */
    vm.setTimers = function () {

        // time for the warning message, this must be triggered half minute before log out
        let timerWarning : number = vm.toMinutes(vm.timer);
        let timerLogout : number = vm.toMinutes(vm.timer) + vm.toMinutes(0.5);

        vm.warningTimer = setTimeout(vm.warningMessage, timerWarning);
        vm.logoutTimer = setTimeout(vm.logout,  timerLogout);
    }

    vm.warningMessage = function () {
        vm.showWarning = true;
        $scope.$apply();
    }

    vm.logout = function () {
        window.location.href = 'logout?inactivitylogout=true'
    }
}

export default DetectInactivityController;
