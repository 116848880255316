import * as angular from 'angular';
import '../filters/path.filter';
import '../filters/processed.filter';

"use strict";

angular.module('ExportModule')
       .controller('ExportController', ExportController);

// Injection of each class
ExportController.$inject = ['$scope', 'ScopeAndLocal', 'Auth', 'Configuration', 'exportService'];

// Controller Constructor
function ExportController($scope, ScopeAndLocal, Auth, Configuration, exportService) {

    // Creating the view model reference
    let vm = this;
    let debug = false;
    let pageWordsJSON = localStorage.getItem('pageWords');

    /**
     * This function will be responsible to check if the current user it
     * is a super user, that will be available the block or not if it is
     * just a common user.
     */
    vm.permissionToShow = async () => {
        if (await Auth.isSuperUser() === true) {
            vm.show = true;
        } else {
            vm.show = false;
        }
    }

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            vm.show = true;
            await vm.permissionToShow()

            vm.response = false;
            vm.data = false;
            vm.pageWords = JSON.parse(pageWordsJSON);
            vm.cartolyticsCustomerIds = await exportService.getCartolyticsCustomersIDs();
            vm.cartolyticsCustomer = {};

            if(debug){
                console.log('[Admin][cartolyticsCustomerId]: ' + vm.cartolyticsCustomerId);
                console.log('[Admin][show]: ' + vm.show);
            }

            await vm.loadScheduledData();

        } catch (e) {
            console.log(e);
        }
    }

    vm.checkboxIsEmpty = (object) => {
        return Object.keys(object).length === 0;
    }

    /**
     * This will be responsible to load the scheduled data.
     */
    vm.loadScheduledData = async () => {
        try
        {
            let response  = await exportService.loadAllScheduledData();

            if (response.status == 400){
                throw new Error(response.data);
            }

            vm.data = response.data;
            vm.error = false;

        } catch (e) {
            vm.error = true;
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will start the process of scheduling the
     * database dump for a vm.cartolyticsCustomer.id.
     */
    vm.scheduleDatabaseDump = async () => {
        try
        {
            // If we cant have the cartolytics customer ID
            if (vm.cartolyticsCustomer.id === undefined || vm.cartolyticsCustomer.id === false ){
                throw new Error('Missing cartolyticsCustomerId');
            }

            let response  = await exportService.scheduleDatabaseDump(vm.cartolyticsCustomer.id);
            if (response.status == 400){
                throw new Error(response.data);
            }
            await vm.loadScheduledData();
            vm.response = response.data;
            vm.error = false;
            $scope.$apply();

        } catch (e) {
            vm.error = true;
            vm.response = e.data;
        }
    }

    /**
     * This will delete a scheduled database dump
     */
    vm.deleteScheduleDatabaseDump = async (id) => {
        try
        {
            // If we cant have the cartolytics customer ID
            if (id === undefined || id === false ){
                throw new Error('Missing ID to delete');
            }

            let response  = await exportService.deleteScheduleDatabaseDump(id);
            if (response.status == 400){
                throw new Error(response.data);
            }
            await vm.loadScheduledData();
            vm.response = response.data;
            vm.error = false;

        } catch (e) {
            vm.error = true;
            vm.response = e.data;
        } finally {
            $scope.$apply();
        }
    }

}


export default ExportController;