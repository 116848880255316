import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .controller('ScanStationFieldRulesController', ScanStationFieldRulesController);

ScanStationFieldRulesController.$inject = ['$scope', 'fieldRuleFactory', 'rulesFactory', 'ScopeAndLocal'];

// Controller Constructor
function ScanStationFieldRulesController($scope, fieldRuleFactory, rulesFactory, ScopeAndLocal) {

    let vm = this;
    vm.scanStation = '';
    vm.main = '';
    vm.rules = '';
    vm.rulesFactory = ''

    vm.$onInit = function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            vm.rulesFactory = rulesFactory;
            rulesFactory.loadScanStation(vm.scanStation);

            vm.rules = vm.loadData();

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will get the data from the scanStation fieldRules.
     */
    vm.loadData = () => {
        return vm.scanStation.fieldRules;
    }

    /**
     * This will check if is valid the added rule.
     * @param field_id
     */
    vm.validateAddedRules = ({field_id}) => {
        return field_id === vm.scanStation.fieldIDForUpdatingRules;
    }

    /**
     * This will clear unwanted rule parameters.
     */
    vm.clearUnwantedRuleParameters = async function () {

        // TODO: remove the newRule from the scanStation and change to rules_parameters
        vm.scanStation.newRule.rules_parameters = await fieldRuleFactory.clearUnwantedRuleParameters(vm.scanStation.newRuleID, vm.scanStation.newRule.rules_parameters);
    }

    /**
     * This will update a scan station field rule data.
     */
    vm.updateScanStationRules = async function (ruleId) {

        try {
            await vm.clearUnwantedRuleParameters();
            vm.scanStation.editNewRuleCase = true;
            await vm.scanStation.editNewRule(ruleId);
            vm.scanStation.showResponseDataAndResponseTypes=0;
            vm.scanStation.scrollTo('add-rules', '.field-rules', true);
            vm.scanStation.editRule = false;

        } catch (e) {
            vm.scanStation.editRule = true;
            console.log(e);
        } finally {
            vm.scanStation.editNewRuleCase = false;
        }

    }

    /**
     * This will trigger the action of add a scan station rule by clicking the
     * button add-rule.
     *
     */
    vm.addScanStationRule = () => {

        try {
            vm.scanStation.addNewRule = true;
            vm.scanStation.newRule = {}
            vm.scanStation.newRule.responseData = {
                failureResponseType : 'Red Warning Reject'
            }


        } catch (e) {
            vm.scanStation.editRule = true;
            console.log(e);
        }

    }

    /**
     * Method that gets the things to confirm before submit a new rule,
     * this was created to remove the issue with order from the frontend,
     * as we have many async and await functions.
     *
     * @param scanStation
     */
    vm.submitRule = async (scanStation) => {
        try
        {
            await vm.clearUnwantedRuleParameters();
            await scanStation.submitNewRule();
            scanStation.showResponseDataAndResponseTypes=0;
        } catch (e) {
            vm.scanStation.editRule = true;
            console.log(e);
        }



    }

}

export default ScanStationFieldRulesController;