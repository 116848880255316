import * as angular from 'angular';
import GridScanStationController from "../../grid/controllers/grid.controller";

"use strict";

angular.module('DragDropModule')
       .directive('dragDropDraggable', dragDropDraggable)
       .directive('dragDropDropTarget', dragDropDropTarget)
       .service('DragDrop', dragDrop);

dragDropDraggable.$inject = ['Configuration', '$rootScope'];

/**
 * Directive object that will build the draggable.
 *
 * @returns {{restrict: string}}
 * @constructor
 */
function dragDropDraggable(Configuration, $rootScope) {
    return ({
        restrict: 'A',
        link: function(scope, el, attrs, controller) {
            angular.element(el).attr("draggable", "true");
            let id = angular.element(el).attr("id");
            if (!id) {
                angular.element(el).attr("id", id);
            }

            el.bind("dragstart", function(e) {

                e.dataTransfer.setData('text/plain', attrs.draggingFieldId);

                $rootScope.$emit("LVL-DRAG-START");
            });

            el.bind("dragend", function(e) {
                $rootScope.$emit("LVL-DRAG-END");
            });
        }
    });
}


/**
 * Directive object that will build the draggable.
 *
 * @returns {{restrict: string}}
 * @constructor
 */
function dragDropDropTarget(Configuration, $rootScope) {
    return ({
        restrict: 'A',
        controller: GridScanStationController,
        link: function(scope, el, attrs, controller) {

            scope.draggingFieldId = attrs.draggingFieldId;
            scope.dropGridId = attrs.dropGridId;

            let id = angular.element(el).attr("id");
            if (!id) {
                angular.element(el).attr("id", id);
            }

            el.bind("dragover", function(e) {
                angular.element(e.target).addClass('dragged-enter');
                if (e.preventDefault) {
                    e.preventDefault(); // Necessary. Allows us to drop.
                }
                e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
                return false;
            });

            el.bind("dragenter", function(e) {
            });

            el.bind("dragleave", function(e) {
                angular.element(e.target).removeClass('dragged-enter');  // this / e.target is previous target element.
            });

            el.bind("drop", async function(e) {

                let draggingFieldId = e.dataTransfer.getData("text/plain");
                controller.changeFieldParentElement(parseInt(attrs.dropGridId), parseInt(draggingFieldId));
                angular.element(e.target).removeClass('dragged-enter');  // this / e.target is previous target element.
                if (e.preventDefault) {
                    e.preventDefault(); // Necessary. Allows us to drop.
                }
                if (e.stopPropogation) {
                    e.stopPropogation(); // Necessary. Allows us to drop.
                }
            });

            $rootScope.$on("LVL-DRAG-START", function() {
                let el = document.getElementById(id);
                angular.element(el).addClass("lvl-target");
            });

            $rootScope.$on("LVL-DRAG-END", function() {
                let el = document.getElementById(id);
                angular.element(el).removeClass("lvl-target");
                angular.element(el).removeClass("lvl-over");
            });
        }
    });
}

/**
 * Service constructor.
 * @constructor
 */
function dragDrop() {
    let service = this;
}
