import * as angular from 'angular';
import Exceptions from '../Exceptions';

"use strict";

angular.module('ExceptionsModule')
       .factory('UnknownExceptionFactory', UnknownExceptionFactory);

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @returns {Configuration}
 * @constructor
 */
function UnknownExceptionFactory() {

    let service = this;

    return {
        throwException: function (message) {
            throw new Exceptions(message, 'UnknownException');
        }
    }

}