import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .controller('ExportedFieldsController', ExportedFieldsController);

// Injection of each class
ExportedFieldsController.$inject = [ '$scope', 'ScopeAndLocal', 'ExportedFieldFactory' ];

// Controller Constructor
function ExportedFieldsController($scope, ScopeAndLocal, ExportedFieldFactory) {

    let vm = this;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            ScopeAndLocal.startVariable(vm, $scope, 'processField');
            vm.dropdownExportedField = undefined;

        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This will return where is localized the exported Fields Array.
     * In our case now it is localized on FieldController (vm.fc).
     */
    vm.getExportedFieldsArray = () => {

        let fieldsArray = [];

        for (const property in vm.fc.initialFieldParams.exportedFieldsArray) {
            fieldsArray = [ ...fieldsArray, JSON.parse(JSON.stringify(vm.fc.initialFieldParams.exportedFieldsArray.exportedFieldsArray[property])) ];
        }

        return fieldsArray;
    }

    /**
     * This will be responsible to add a new field that we should be exporting it.
     * @param field
     */
    vm.addExportedField = function (field) {

        let exportedArray = vm.fc.initialFieldParams.exportedFieldsArray == undefined
            ? new Array()
            : JSON.parse(JSON.stringify(vm.fc.initialFieldParams.exportedFieldsArray));

        // adding new data to a new array variable
        let newArray = ExportedFieldFactory.addExportedField(exportedArray, field);

        // setting the new array of data
        vm.fc.initialFieldParams.exportedFieldsArray = newArray;
    }

    /**
     * This will be responsible to remove a  field that we shouldn't be exported.
     * @param field
     */
    vm.removeExportedField = function (field) {
        vm.fc.initialFieldParams.exportedFieldsArray = ExportedFieldFactory.removeExportedField(
            vm.fc.initialFieldParams.exportedFieldsArray, field
        );
    }

}

export default ExportedFieldsController;