import * as angular from 'angular';
import ScanStationFieldRulesController from "./controllers/scanStation.field.rules.controller";
import FieldRuleController from "./controllers/fieldRule.controller";
import PrintRuleController from "./controllers/printRule.controller";

"use strict";

let FieldRuleComponent = {
    templateUrl: 'components/field-rule/template/field-rule.html?random=' + (Math.random() * (1.00 - 0.00)),
    controller: ScanStationFieldRulesController,
    controllerAs: 'frs',
    bindings: {
        scanStation: '=',
        main: '=',
    }
}

angular.module('FieldModule').component('fieldRule', FieldRuleComponent);


let customResponsePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/custom_response/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        main: '=',
        rule: '='
    }
}
angular.module('FieldModule').component('customResponsePrint', customResponsePrintDirective);

let customResponseFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/custom_response/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('customResponseField', customResponseFieldDirective);


let cannotBeDuplicateFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_be_duplicate/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('cannotBeDuplicateField', cannotBeDuplicateFieldDirective);

let cannotBeDuplicatePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_be_duplicate/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('cannotBeDuplicatePrint', cannotBeDuplicatePrintDirective);

let cannotBeEmptyFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_be_empty/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('cannotBeEmptyField', cannotBeEmptyFieldDirective);

let cannotBeEmptyPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_be_empty/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('cannotBeEmptyPrint', cannotBeEmptyPrintDirective);

let mustBeEqualToFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_equal_to/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustBeEqualToField', mustBeEqualToFieldDirective);

let mustBeEqualToPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_equal_to/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustBeEqualToPrint', mustBeEqualToPrintDirective);

let cannotBeEqualToFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_be_equal_to/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('cannotBeEqualToField', cannotBeEqualToFieldDirective);

let cannotBeEqualToPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_be_equal_to/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('cannotBeEqualToPrint', cannotBeEqualToPrintDirective);

let mustBeBetweenTwoValuesFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_between_two_values/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustBeBetweenTwoValuesField', mustBeBetweenTwoValuesFieldDirective);

let mustBeBetweenTwoValuesPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_between_two_values/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustBeBetweenTwoValuesPrint', mustBeBetweenTwoValuesPrintDirective);

let mustBeAtLeastXNumberOfCharactersFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_at_least_x_number_of_characters/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustBeAtLeastXNumberOfCharactersField', mustBeAtLeastXNumberOfCharactersFieldDirective);

let mustBeAtLeastXNumberOfCharactersPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_at_least_x_number_of_characters/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustBeAtLeastXNumberOfCharactersPrint', mustBeAtLeastXNumberOfCharactersPrintDirective);

let mustBeExactlyXNumberOfCharactersFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_exactly_x_number_of_characters/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustBeExactlyXNumberOfCharactersField', mustBeExactlyXNumberOfCharactersFieldDirective);

let mustBeExactlyXNumberOfCharactersPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_exactly_x_number_of_characters/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustBeExactlyXNumberOfCharactersPrint', mustBeExactlyXNumberOfCharactersPrintDirective);

let mustNotExistElsewhereFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_not_exist_elsewhere/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustNotExistElsewhereField', mustNotExistElsewhereFieldDirective);

let mustNotExistElsewherePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_not_exist_elsewhere/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustNotExistElsewherePrint', mustNotExistElsewherePrintDirective);


let mustExistElsewhereFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_exist_elsewhere/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustExistElsewhereField', mustExistElsewhereFieldDirective);

let mustExistElsewherePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_exist_elsewhere/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustExistElsewherePrint', mustExistElsewherePrintDirective);

let specificValueRequiredElsewhereFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/specific_value_required_elsewhere/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('specificValueRequiredElsewhereField', specificValueRequiredElsewhereFieldDirective);

let specificValueRequiredElsewherePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/specific_value_required_elsewhere/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '=',
        registerParent: '&', 
        loadParent: '='
    },
}
angular.module('FieldModule').component('specificValueRequiredElsewherePrint', specificValueRequiredElsewherePrintDirective);

let specificValueCannotBePresentElsewhereFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/specific_value_cannot_be_present_elsewhere/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('specificValueCannotBePresentElsewhereField', specificValueCannotBePresentElsewhereFieldDirective);

let specificValueCannotBePresentElsewherePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/specific_value_cannot_be_present_elsewhere/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('specificValueCannotBePresentElsewherePrint', specificValueCannotBePresentElsewherePrintDirective);

let cannotExistInAnotherStationFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_exist_in_another_station/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('cannotExistInAnotherStationField', cannotExistInAnotherStationFieldDirective);

let cannotExistInAnotherStationPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/cannot_exist_in_another_station/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('cannotExistInAnotherStationPrint', cannotExistInAnotherStationPrintDirective);

let populateAnotherLocalFieldWithDataFromElsewhereFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_another_local_field_with_data_from_elsewhere/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('populateAnotherLocalFieldWithDataFromElsewhereField', populateAnotherLocalFieldWithDataFromElsewhereFieldDirective);

let populateAnotherLocalFieldWithDataFromElsewherePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_another_local_field_with_data_from_elsewhere/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('populateAnotherLocalFieldWithDataFromElsewherePrint', populateAnotherLocalFieldWithDataFromElsewherePrintDirective);

let populateAnotherLocalFieldWithTotalFromElsewhereFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_another_local_field_with_total_from_elsewhere/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('populateAnotherLocalFieldWithTotalFromElsewhereField', populateAnotherLocalFieldWithTotalFromElsewhereFieldDirective);

let populateAnotherLocalFieldWithTotalFromElsewherePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_another_local_field_with_total_from_elsewhere/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('populateAnotherLocalFieldWithTotalFromElsewherePrint', populateAnotherLocalFieldWithTotalFromElsewherePrintDirective);

let mustHaveMatchingEquivalentDataInAnotherStationFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_have_matching_equivalent_data_in_another_station/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustHaveMatchingEquivalentDataInAnotherStationField', mustHaveMatchingEquivalentDataInAnotherStationFieldDirective);

let mustHaveMatchingEquivalentDataInAnotherStationPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_have_matching_equivalent_data_in_another_station/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustHaveMatchingEquivalentDataInAnotherStationPrint', mustHaveMatchingEquivalentDataInAnotherStationPrintDirective);

let populateLocalFieldWithDataFromAnotherLocalFieldFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_local_field_with_data_from_another_local_field/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('populateLocalFieldWithDataFromAnotherLocalFieldField', populateLocalFieldWithDataFromAnotherLocalFieldFieldDirective);

let populateLocalFieldWithDataFromAnotherLocalFieldPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_local_field_with_data_from_another_local_field/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('populateLocalFieldWithDataFromAnotherLocalFieldPrint', populateLocalFieldWithDataFromAnotherLocalFieldPrintDirective);

let populateLocalFieldWithPartOfThisFieldFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_local_field_with_part_of_this_field/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('populateLocalFieldWithPartOfThisFieldField', populateLocalFieldWithPartOfThisFieldFieldDirective);

let populateLocalFieldWithPartOfThisFieldPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/populate_local_field_with_part_of_this_field/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('populateLocalFieldWithPartOfThisFieldPrint', populateLocalFieldWithPartOfThisFieldPrintDirective);

let mustBeCertainFormatFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_certain_format/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustBeCertainFormatField', mustBeCertainFormatFieldDirective);

let mustBeCertainFormatPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_certain_format/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustBeCertainFormatPrint', mustBeCertainFormatPrintDirective);

let mustNotBeLocalDuplicateFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_not_be_local_duplicate/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustNotBeLocalDuplicateField', mustNotBeLocalDuplicateFieldDirective);

let mustNotBeLocalDuplicatePrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_not_be_local_duplicate/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustNotBeLocalDuplicatePrint', mustNotBeLocalDuplicatePrintDirective);

let mustBeEqualToAnotherLocalFieldFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_equal_to_another_local_field/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('mustBeEqualToAnotherLocalFieldField', mustBeEqualToAnotherLocalFieldFieldDirective);

let mustBeEqualToAnotherLocalFieldPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/must_be_equal_to_another_local_field/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('mustBeEqualToAnotherLocalFieldPrint', mustBeEqualToAnotherLocalFieldPrintDirective);

let numbersOnlyFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/numbers_only/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('numbersOnlyField', numbersOnlyFieldDirective);

let numbersOnlyPrintPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/numbers_only/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('numbersOnlyPrint', numbersOnlyPrintPrintDirective);

let keepDataForNextScanFieldDirective = {
    templateUrl: 'components/field-rule/template/directives/keep_data_for_next_scan/field.html',
    controller: FieldRuleController,
    controllerAs: 'fr',
    bindings: {
        scanStation: '=',
        main: '=',
        registerParent: '&', 
        loadParent: '='
    }
}
angular.module('FieldModule').component('keepDataForNextScanField', keepDataForNextScanFieldDirective);

let keepDataForNextScanPrintDirective = {
    templateUrl: 'components/field-rule/template/directives/keep_data_for_next_scan/print.html',
    controller: PrintRuleController,
    controllerAs: 'pr',
    bindings: {
        scanStation: '=',
        rule: '='
    },
}
angular.module('FieldModule').component('keepDataForNextScanPrint', keepDataForNextScanPrintDirective);
