import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .factory('ExportedFieldFactory', ExportedFieldFactory);


ExportedFieldFactory.$inject = [];


/**
 * Object that will be responsible to export fields to a file using the
 * file-builder component.
 */
function ExportedFieldFactory() {

    let factory = this;

    /**
     * This will make sure that search though the array of fields,
     * compare the index "id", and if it cant find, will return false, so
     * this is the only case that will be adding data to it.
     *
     * @param exportedFieldsArray
     * @param field
     * @param propertyToSearch
     */
    factory.addExportedField = (exportedFieldsArray : any[], field, propertyToSearch = 'id') =>
    {

        // If cant find inside of the array
        if (!exportedFieldsArray.find(obj => obj[propertyToSearch] === field.id)) {
            exportedFieldsArray = [...exportedFieldsArray, JSON.parse(field)];
        }
        return exportedFieldsArray;
    }

    /**
     * This will loop though the array, and check for the property to search on both places
     * on the field and on the array property, if both are the same, we will be removing
     * by the array index.
     * @param exportedFieldsArray
     * @param field
     * @param propertyToSearch
     */
    factory.removeExportedField = (exportedFieldsArray : any[], field, propertyToSearch = 'id') => {
        if(exportedFieldsArray !== undefined){
            for (let index = 0; index < exportedFieldsArray.length; index++) {

                if (exportedFieldsArray[index][propertyToSearch] === field[propertyToSearch]) {
                    exportedFieldsArray.splice(index, 1);
                }

            }
        }
        return exportedFieldsArray;
    }

    return factory;
}
