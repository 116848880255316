import * as angular from 'angular';
import FieldController from "./controllers/field.controller";

"use strict";

let getModulesByKeyWord =  {
    templateUrl: 'components/field/template/common/get-modules-by-keyword.html',
    controller: FieldController,
    controllerAs: 'fc',
    bindings: {
        fieldParameterToProcess: '@',
        initialFieldParamsPassed: '=',
    }
}

angular.module('FieldModule')
    .component('getModulesByKeyWord', getModulesByKeyWord);