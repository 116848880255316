import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import FileBuilderController from "./controllers/file-builder.controller";

"use strict";

class FileBuilderComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/fileBuilder';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '=',
        field: '=',
        fc: '=',

        data: '=',
        row: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = FileBuilderController;

    // This will set an alias
    public controllerAs: string = 'bcontroller';
}

angular.module('FieldModule').component('fileBuilder', <StandardComponent> new FileBuilderComponent);