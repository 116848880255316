import * as angular from 'angular';

'use strict';

angular.module('DatetimeModule')
       .service('datetime', Datetime);

Datetime.$inject = ['$locale'];

function Datetime($locale) {

    let service = this;

    /**
     * This will be responsible to update anything related
     * to the daytime field configuration.
     */
    service.configuration = async () => {

        // This configuration you can find on node_modules/angularjs-datetime-picker/angularjs-datetime-picker.js
        // checked that something was changing to 6 (Saturday), so putting back to 0 (sunday)
        // check patters on: https://github.com/angular/angular.js/blob/master/src/ngLocale/angular-locale_eu.js
        $locale.DATETIME_FORMATS.FIRSTDAYOFWEEK = 0;
    }

    return service;

}

export default Datetime;
