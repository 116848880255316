import * as angular from 'angular';
import Exceptions from '../Exceptions';

"use strict";

class ArgumentUndefinedException extends Exceptions
{
    constructor(message, type) {
        !message ? message = "One or more of the arguments are undefined!"
            : message = `Missing the argument: ${message}`;
        super(message, type);
    }
}

angular.module('ExceptionsModule')
       .factory('ArgumentUndefinedExceptionFactory', ArgumentUndefinedExceptionFactory);

/**
 * Object that will be responsible to store all configuration's
 * data from the whole application
 *
 * @returns {Configuration}
 * @constructor
 */
function ArgumentUndefinedExceptionFactory()
{
    return {
        throwException : function (message) {
            throw new ArgumentUndefinedException(message, 'ArgumentUndefinedException');
        }
    }
}
