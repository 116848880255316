import * as angular from 'angular';
import ExportController from "./controllers/export.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class BlocworxExportComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/export';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        action: '@',
        main: '=',
    }

    // This will set what will be the controller of this component
    public controller : any = ExportController;

    // This will set an alias
    public controllerAs: string = 'exp';
}

angular.module('ExportModule').component('blocworxExport', <StandardComponent> new BlocworxExportComponent);
