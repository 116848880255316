/// <reference types="web-bluetooth" />
import * as angular from 'angular';
import * as $ from "jquery";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('BluetoothController', BluetoothController);

// Injection of each class
BluetoothController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'sound', 'status', 'Blocworx', 'BluetoothFactory', '$window'];

// Controller Constructor
function BluetoothController($rootScope, $scope, ScopeAndLocal, sound, status, Blocworx, BluetoothFactory, $window) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            status.runAfterSuccess(vm.runAfterSuccess, vm.scanStation);

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * TODO: Raf to change code this function is not being relied up
     */
     
    vm.runAfterSuccess = async () => {

    }


    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * Process the byte data and make it a value in Blocworx, and then call the rules on that value
     * @param event
     * @param field
     */
    async function handleDataReading(event, field) {

        let outputValue = await BluetoothFactory.processBluetoothData(event, field.characteristicUUID);

        vm.scanStation.scanStationObj.dataToAdd[field.field_slug] = outputValue;

        vm.scanStation.getRules('no-event',
            field.id,
            field.special_field_key,
            vm.scanStation.scanStationObj.dataToAdd[field.field_slug],
            field.fieldIndex,
            field.field_slug
        );
    }

    /**
     * This function handles a service provided and then processes the data by passing a characteristic into an event handler
     * In future there may be many characteristics etc
     * It makes use of the following Chrome Feature: https://chromestatus.com/feature/5264933985976320
     * Sample Code here: https://googlechrome.github.io/samples/web-bluetooth/
     */

    vm.triggerDeviceConnection = async function (field) {

        var button = document.getElementById('bluetooth-connect-button-' + field.id);

        if (!('bluetooth' in navigator)) {
            alert('Bluetooth API not supported on your browser.');
        } else {
            button.addEventListener('click', async function (event) {

                let options: RequestDeviceOptions = {
                    'acceptAllDevices': true,
                    'optionalServices': [field.serviceUUID]
                }

                try {
                    let device = await navigator.bluetooth.requestDevice(options);
                    let server = await device.gatt.connect();
                    let service = await server.getPrimaryService(field.serviceUUID);
                    let characteristic = await service.getCharacteristic(field.characteristicUUID);

                    var temperatureCharacteristic = characteristic;

                    characteristic.startNotifications();

                    temperatureCharacteristic.addEventListener('characteristicvaluechanged', (e) => {
                            handleDataReading(e, field)
                        }
                    );

                    const myInterval = setInterval(async function () {

                        try {
                            await characteristic.readValue();
                        } catch (e) {

                            let errorString = e.toString();

                            //TODO find a more official way to handle a bluetooth disconnected error
                            if (errorString.includes('disconnected')) {

                                if (vm.connectedDevices == null) {
                                    vm.connectedDevices = [];
                                }

                                // showing the button again
                                vm.connectedDevices[field.field_slug] = null;

                                $scope.$apply();

                                alert('The device has been disconnected');

                                // now that we're disconnected we dont need to run the interval anymore

                                clearInterval(myInterval);
                            }
                        }
                    }, 2000);

                    if (vm.connectedDevices == null) {
                        vm.connectedDevices = [];
                    }

                    // now we can see the device on the screen and the connect button gets removed
                    vm.connectedDevices[field.field_slug] = device.name;

                } catch (error) {

                    // there is a problem, so we need to make sure the connect button is appearing and nothing else

                    if (vm.connectedDevices == null) {
                        vm.connectedDevices = [];
                    }
                    vm.connectedDevices[field.field_slug] = null;
                }
            });
        }

    }


    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

}

export default BluetoothController;