import * as angular from 'angular';
import jQuery from "jquery";
import StatusService from "components/blocworx/services/status.service";

declare const window: any;

"use strict";

angular.module('FieldModule')
    .controller('BlocworxGatewayController', BlocworxGatewayController);

// Injection of each class
BlocworxGatewayController.$inject = ['$rootScope', '$scope', 'ScopeAndLocal', 'sound', 'status', 'Blocworx', 'BlocworxGatewayFactory', '$window'];

// Controller Constructor
function BlocworxGatewayController($rootScope, $scope, ScopeAndLocal, sound, status, Blocworx, BlocworxGatewayFactory, $window) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            status.runAfterSuccess(vm.runAfterSuccess, vm.scanStation);
            vm.showBulkUpload = [];

            vm.blocworxGatewayConditions = {}
            vm.hours = BlocworxGatewayFactory.createHourArray();
            vm.days = BlocworxGatewayFactory.createDayArray();

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * TODO Raf : I needed to do my own one because runAfterSuccess didnt work on second submit
     */

    $rootScope.$on('cleanBlocworxGatewayFiles', async function (event, value) {
        await vm.runAfterSuccess();
        $scope.$apply()
    });
    
    /**
     * This functions initialises the listener which gets triggered when a Blocworx Gateway call is complete
     */

    vm.triggerPostBlocworxGatewayCall = () => {

        $scope.$on('triggerPostBlocworxGatewayCall', async function (event, eventData) {
            
            let field = vm.scanStation.scanStationFieldIdsToField[eventData.fieldId];
            
            if(field.populateBlocFields == 1) {

                let data = eventData.response.data.data;
                angular.forEach(data, async function(value, fieldSlug) {
                    vm.scanStation.scanStationObj.dataToAdd[fieldSlug] = value;
                    if(field.triggerRulesOnFields == 1) {

                        await vm.scanStation.getRules('no-event', field.id, field.special_field_key, value, field.domIndex, fieldSlug);    
                    }
                })
            }
            
        });
    }


    vm.runAfterSuccess = async () => {

    }


    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }
    
    /**
     * Pending Usage for Version 3.0.0
     */
    

    vm.triggerBlocworxGatewayUsingButton = async function(stationId, fieldIds, dataToAdd) {
        vm.pleasewait = true;
        try {
            await BlocworxGatewayFactory.triggerActions(stationId, fieldIds, dataToAdd);
            alert('Mail Successfully Sent')
        } catch (err) {
            alert('Something went wrong with this mail alert');
        }

        vm.pleasewait = false;
        $scope.$apply();
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    /**
     *  This will add a condition to the list of conditions
     * 
     * @param localFieldId  id of the local field
     * @param condition  condition to be added
     * @param valueToCompare  value to compare
     */
    vm.addCondition = function (localFieldId, condition, valueToCompare) {

        try {
            if (vm.fc.initialFieldParams.blocworxGatewayConditions) {
                vm.blocworxGatewayConditions = vm.fc.initialFieldParams.blocworxGatewayConditions
            }
            
            BlocworxGatewayFactory.validate(localFieldId, condition, valueToCompare);
            vm.blocworxGatewayConditions = BlocworxGatewayFactory.add(vm.blocworxGatewayConditions, localFieldId, condition, valueToCompare);
            vm.fc.initialFieldParams.blocworxGatewayConditions = vm.blocworxGatewayConditions

        } catch (err) {
            alert(err);
        }
    }

    /**
     *  This function will remove a condition from the list of conditions
     * @param condition condition to be removed
     */
    vm.removeCondition = function (condition) {

        vm.blocworxGatewayConditions = BlocworxGatewayFactory.remove(vm.blocworxGatewayConditions, condition);
        vm.fc.initialFieldParams.blocworxGatewayConditions = vm.blocworxGatewayConditions
    }
}

export default BlocworxGatewayController;