import * as angular from 'angular';
import NewRule from "../model/newRule";

"use strict";

angular.module('FieldModule')
       .controller('PrintRuleController', PrintRuleController);

PrintRuleController.$inject = ['$scope', 'fieldRuleService', 'rulesFactory'];

// Controller Constructor
function PrintRuleController($scope, fieldRuleService, rulesFactory) {

    // Creating the view model reference
    let vm = this;
    vm.rule = {};
    vm.scanStation = {};
    vm.selectedOption = '';
    vm.rulesFactory = ''
    vm.childRef;

    vm.register = function(child){
        vm.childRef = child;
    };

    vm.$onInit = function () {
        try {

            $scope.rule = vm.rule;
            $scope.scanStation = vm.scanStation;
            vm.rulesFactory = rulesFactory;
            rulesFactory.loadScanStation(vm.scanStation).loadScope($scope);

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be loading teh rules parameters for the
     * edit case.
     *
     * @param rule
     */
    vm.loadEditCase = async function (rule) {
        try {

            // loading the value for rule_parameters
            let response = await fieldRuleService.loadScanStationFieldRule(rule.id, rule.scan_station_id, rule.field_id);
            let ruleData = response.data.rule;
            vm.scanStation.selectedRuleID = rule.rule_condition_id;

            let responseJson = JSON.parse(ruleData.response_data)
            vm.scanStation.showResponseDataAndResponseTypes =
                responseJson != null && Object.keys(responseJson).length > 1 ? 1 : 0

            let newRuleData = new NewRule().build(ruleData);

            $scope.scanStation.newRule = newRuleData;
            $scope.scanStation.newRule.rules_parameters = newRuleData.rulesParameters;
            $scope.scanStation.newRuleID = vm.selectedOption = rule.rule_condition_id;
            $scope.scanStation.fieldRuleId = rule.id;

            vm.childRef.loadStationFieldsForRule()

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }
}

export default PrintRuleController;