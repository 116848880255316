import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('FileBuilderController', FileBuilderController);

// Injection of each class
FileBuilderController.$inject = ['$scope', 'ScopeAndLocal', 'FileBuilderFactory',
    '$state', 'MessageService'];

// Controller Constructor
function FileBuilderController($scope, ScopeAndLocal, FileBuilderFactory,
             $state, MessageService) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            vm.files = {};
            vm.scanStationData = [];
            vm.openModal = false;
            vm.counter = 0;

            await vm.scanStation.form.addValidator({
                name : 'file-builder',
                method : vm.validate
            });

            $scope.$on('addedScanStationData', async function (event, scanStationData) {
                if (!event.defaultPrevented) {
                    event.defaultPrevented = true;

                    // building the form fields data (all the sent data from the form)
                    scanStationData.formFields = vm.scanStation.getAllFieldsData();

                    let fileResponse = await FileBuilderFactory.saveFile(scanStationData);
                    if(fileResponse.status == 200){
                        MessageService.addSuccessMessage(fileResponse.data.response);
                    }
                }
            });

        } catch (e) {
            console.log(e);
        }
    }





    /**
     * This will validate the file builder field.
     *
     * @param scanStationData
     */
    vm.validate = async (scanStationData) => {
        try
        {
            vm.scanStation.form.validated = false;
            scanStationData.formFields = vm.scanStation.getAllFieldsData();
            await FileBuilderFactory.fileBuilderValidate(scanStationData);
            vm.scanStation.form.validated = true;

        } catch ( exception ){
            throw exception
        }
    }


    /**
     * This will return the form fields.
     */
    vm.getFormFields = () => {

        let formFields = [];
        const allFieldsData = vm.scanStation.getAllFieldsData();

        // filtering the fields, removing fields with data == undefined
        Object.keys(allFieldsData).forEach( (key) => {
            if(allFieldsData[key].data !== undefined){
                formFields = [...formFields, allFieldsData[key]]
            }
        });

        return formFields;
    }

    /**
     * This will be responsible to build the file.
     *
     * @param fieldSlug
     * @param fieldId
     */
    vm.buildFile = async (fieldId) => {

        vm.showLoader = true;
        try
        {
            let formFields = vm.getFormFields();

            let fileResponse = await FileBuilderFactory.buildFile(
                formFields,
                fieldId
            );

            if(fileResponse.status == 200){
                MessageService.addSuccessMessage(fileResponse.data.data);
            }

            vm.showLoader = false;

        } catch (e) {
            console.log(e);
            MessageService.addErrorMessage(e.data.response);
            vm.showLoader = false;
            alert('Something has went wrong, please check your field parameters or contact support@blocworx.com')
        } finally {
            $scope.$apply();
        }

    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type == fieldType;
    }

    /**
     * This method will be responsible to load the scan station data
     * for this field.
     */
    vm.loadScanStationData = async (field, row) => {
        try
        {
            vm.scanStationData = await FileBuilderFactory.loadScanStationData(
                field,
                row
            );

        } catch (e) {
            console.log(e);
            MessageService.addErrorMessage(e.data.response);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * First part
     * @param filepath
     */
    vm.downloadFile = async (filepath, filename) => {
        try
        {
            let downloadFile = await FileBuilderFactory.downloadFile(filepath);

            let url = window.URL || window.webkitURL;
            let objectUrl = url.createObjectURL(downloadFile.data)

            // TODO: recreate this for all browsers later on
            let downloadLink = angular.element('<a></a>');
            downloadLink.attr('href', objectUrl);
            downloadLink.attr('target','_self');
            downloadLink.attr('download', filename);
            downloadLink[0].click();

        } catch (e) {
            console.log(e);
            MessageService.addErrorMessage(e.data.response);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will open the modal with data from
     * the table file_builder
     */
    vm.open = () => {
        vm.openModal = true;
        vm.scanStation.modalOn();
    }

    /**
     * This will close the modal with data from
     * the table file_builder
     */
    vm.close = () => {
        vm.openModal = false;
        vm.scanStation.modalOff();
    }


}


export default FileBuilderController;