import * as angular from 'angular';
import DatetimeController from "./controllers/datetime.controller";


"use strict";

let FromDateComponent =  {
    templateUrl: 'components/datetime/template/from-date.html',
    controller: DatetimeController,
    controllerAs: 'date',
    bindings: {
        data: '<',
        dateOnly: '@',
        model: '<',
    }
}


let ToDateComponent =  {
    templateUrl: 'components/datetime/template/to-date.html',
    controller: DatetimeController,
    controllerAs: 'date',
    bindings: {
        data: '<',
        dateOnly: '@',
        model: '<',
    }
}

angular.module('DatetimeModule')
       .component('fromDate', FromDateComponent)
       .component('toDate', ToDateComponent);
