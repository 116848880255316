import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('FieldRuleHoverController', FieldRuleHoverController);

FieldRuleHoverController.$inject = ['$scope','ScopeAndLocal'];

// Controller Constructor
function FieldRuleHoverController($scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'grid');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'key');
            ScopeAndLocal.startVariable(vm, $scope, 'child');

        } catch (e) {
            console.log(e);
        }
    }

}

export default FieldRuleHoverController;