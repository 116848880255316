import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('linkField', LinkField);

/**
 * Service constructor.
 * @constructor
 */
function LinkField() {
    let service = this;

    /**
     * This will load the initial parameters.
     */
    service.getInitialParams = function () {
        return {
            'dropdownFromStationLookUp': {
                'validation' : {
                    'required': false,
                }
            },
            'buttonText': {
                'validation' : {
                    'required': false,
                }
            },
            'opensInNewTab':  {
                'validation' : {
                    'required': false,
                }
            },
            'buttonColour': {
                'validation' : {
                    'required': false,
                }
            },
            'fieldLinkArray': {
                'validation' : {
                    'required': false,
                }
            },
        };
    }

}