import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('LinkController', LinkController);

// Injection of each class
LinkController.$inject = ['$scope', 'ScopeAndLocal', 'LinkFieldFactory', '$state'];

// Controller Constructor
function LinkController($scope, ScopeAndLocal, LinkFieldFactory, $state) {

    let vm = this;
    vm.title = "Link Data Field";

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');

            vm.linkFactory = LinkFieldFactory

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be responsible to add a new field link.
     *
     * @param field
     */
    vm.addNewFieldLink = async (field) => {
        vm.fc.initialFieldParams.fieldLinkArray = await LinkFieldFactory.addNewFieldLink(vm.fc.initialFieldParams.fieldLinkArray, field);
        $scope.$apply();
    }

    /**
     * This will be responsible to remove data rom the fieldLinkArray.
     *
     * @param field
     */
    vm.removeFieldLink = async (index) => {
        vm.fc.initialFieldParams.fieldLinkArray = await LinkFieldFactory.removeFieldLink(vm.fc.initialFieldParams.fieldLinkArray, index);
        $scope.$apply();
    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    /*
    method to build the json for the link
     */
    vm.buildJsonForSingleRecord = async (field, row) => {
        try
        {
            let fieldId = field.id;
            vm.linkFactory.loadLinkData(vm.scanStation.scanStationFieldIdsToField, fieldId, row);
            vm.url = vm.getLinkUrl(field, row);
        } catch (e){
            console.log(e);
        }


    }

    /**
     * This will build the link url.
     *
     * @param field
     * @param row
     */
    vm.getLinkUrl = (field, row) => {

        let uniqueModuleSlug = field.dropdownFromStationLookUp.uniqueModuleSlug;
        let stationId = field.dropdownFromStationLookUp.stationId;
        let urlFieldSlugData = vm.linkFactory.getLinkData(row, field);
        let paramsToLoad = {
            'uniqueModuleSlug' : uniqueModuleSlug,
            'stationId' : stationId,
            'urlFieldSlugData': urlFieldSlugData
        }

        return $state.href('bloc', paramsToLoad);
    }

}

export default LinkController;