import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('checkboxFromStationField', CheckboxFromStationField);

/**
 * Service constructor.
 * @constructor
 */
function CheckboxFromStationField() {
    let service = this;

    /**
     * This will load the initial parameters.
     */
    service.getInitialParams = function () {
        return {
            'dropdownFromStationLookUp': {
                'subParameters': {
                    'stationId': {
                        'validation' : {
                            'required': true,
                            'validationResponse': 'Please choose a bloc to look up.',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    },
                    'fieldIdToLookUp': {
                        'validation' : {
                            'required': true,
                            'validationResponse': 'Select the remote field that will be searched against.',
                            'requiredType': 'stringMustNotBeEmpty'
                        }
                    }
                },
                'validation' : {
                    'required': true,
                    'validationResponse': 'Please select a bloc and a field to look up',
                    'requiredType': 'listMustHaveOne'
                }
            },
            'dropDownFromStationExcludeMatchingDataFromAnotherFieldData': {
                'validation' : {
                    'required': false,
                }
            },
            'localFieldMatchingFieldPairs': {
                'validation' : {
                    'required': false,
                }
            },
            'dropdownFromStationReverseOrder': {
                'validation' : {
                    'required': false,
                }
            }
        };
    }

}