import * as angular from 'angular';
import * as jQuery from "jquery";

"use strict";

angular.module('FieldModule')
       .controller('CheckboxFromStationController', CheckboxFromStationController);

// Injection of each class
CheckboxFromStationController.$inject = ['$scope', 'ScopeAndLocal'];

// Controller Constructor
function CheckboxFromStationController($scope, ScopeAndLocal) {

    let vm = this;

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');
            if(vm.field !== undefined && vm.field.field_slug){
                vm.scanStation.stationDataForDropDown[vm.field.field_slug] = [];
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will make sure that the will load station data for the
     * dropdown.
     */
    vm.syncCheckboxes = () => {

        let localFieldMatchingFieldPairs = vm.field.localFieldMatchingFieldPairsArray;
        let localFieldExclusionMatchingFieldPairs = vm.field.localFieldExclusionMatchingFieldPairsArray;
        vm.scanStation.getStationDataForDropDown(
            vm.field.field_slug,
            vm.field.dropdownFromStationLookUp,
            vm.field.dropdownFromStationOnlyDataAfterDate,
            localFieldMatchingFieldPairs,
            vm.field.dropdownFromStationReverseOrder,
            vm.field.dropDownFromStationExcludeMatchingDataFromAnotherFieldData,
            localFieldExclusionMatchingFieldPairs);
    }

    /**
     * This cleans all the checkboxes.
     */
    vm.cleanAll = (fieldSlug) => {
        vm.scanStation.scanStationObj.dataToAdd[fieldSlug] = {}
    }

    /**
     * This will be selecting all inputs of the .checboxFromStation
     * case.
     */
    vm.selectAll = (fieldId) => {
        jQuery('#scanStationForm-' + fieldId + ' input.checkboxFromStation').map((index, data) => {
            if(!jQuery(data).is(":checked")){
                jQuery(data).trigger( "click");
            }
        })
    }

    /**
     * This is the place that we should download data from.
     * @param field
     */
    vm.getDropdownData = (field) => {
        return vm.scanStation.stationDataForDropDown[field.field_slug];
    }

    /**
     * This will show only if we contain data on the stationDataForDropdown
     */
    vm.existDataToShow = (field) => {

        let existDataToShow = false;

        if(field != undefined && field.field_slug){
            if(vm.scanStation.stationDataForDropDown[field.field_slug] != undefined && vm.scanStation.stationDataForDropDown[field.field_slug].length > 0){
                existDataToShow = true;
            }
        }
        return existDataToShow;
    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }


}

export default CheckboxFromStationController;