import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular
    .module('FieldModule')
    .factory('BarcodeGeneratorFactory', BarcodeGeneratorFactory);


BarcodeGeneratorFactory.$inject = ['fieldService', 'Configuration', '$http'];


/**
 * Object that will create a Plain Data Factory, all methods of factory
 * will be available from outside.
 */
function BarcodeGeneratorFactory(fieldService, Configuration, $http) {
    let factory = this;

    /*
    This function will generate the barcode from a string
     */
    factory.generateBarcodeFromString = function (string, codeType, height) {

        let data = {
            string: string,
            codeType: codeType,
            height: height
        };

        let apiBase = Configuration.getApiUrl() + 'generate-barcode-from-string';

        return $http.post(apiBase, data);

    }


    return factory;
}

