import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('FieldModule')
       .controller('CreateUserPopUpController', CreateUserPopUpController);

// Injection of each class
CreateUserPopUpController.$inject = ['$scope', 'ScopeAndLocal', 'CreateUserPopUpFieldFactory', '$window'];

// Controller Constructor
function CreateUserPopUpController($scope, ScopeAndLocal, CreateUserPopUpFieldFactory, $window) {

    let vm = this;
    vm.title = "Create User PopUp Field";

    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'field');
            ScopeAndLocal.startVariable(vm, $scope, 'row');

            vm.userPopUpFields = [];

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Set what is the active field for the controller build of this
     * component.
     * @param fieldName
     */
    vm.activeField = (fieldName) => {
        vm.scanStation.activeField = fieldName;
    }

    /**
     * This will do the check to see if this filed should
     * show or hide.
     * @param fieldType
     */
    vm.showField = (fieldType) => {
        return vm.scanStation.newField.field_type === fieldType;
    }

    /**
     * This is to build the url that will eventually show the Manage Users in an iframe
     */

    vm.buildManageUserUrl =async function(field) {

        vm.userPopUpFields[field.field_slug] = await CreateUserPopUpFieldFactory.buildManageUsersUrl(vm.scanStation.id, field, vm.scanStation.scanStationObj.dataToAdd, vm.scanStation.scanStationFieldIdsToField);
        $scope.$apply();

    }

    // receive a message from the iframe to close the iframe pop up once the user has been added
    // ref application/components/users/controllers/users.controller.ts

    $window.addEventListener('message', async function (data) {
        if(data.data == 'closeAddUserIframe') {
            angular.forEach(vm.showIframe, function(value, fieldSlug) {
                if(vm.scanStation.scanStationFieldSlugsToField[fieldSlug] != null && vm.scanStation.scanStationFieldSlugsToField[fieldSlug].submitFormAfterUserAdded == 1) {
                    $('.submit-button').trigger('click');
                }
            })

            angular.forEach(vm.showIframe, function(value, fieldSlug) {
                vm.showIframe[fieldSlug] = false;
            });
            $scope.$apply();

        }
    });
}

export default CreateUserPopUpController;