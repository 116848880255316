import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import CustomerDetailsController from "./controllers/customerDetails.controller";

"use strict";

class CustomerDetailsComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field/components/customerDetails';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        field: '<',
        row: '<',
        fc: '<',
        main: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = CustomerDetailsController;

    // This will set an alias
    public controllerAs: string = 'cd';
}

angular.module('FieldModule').component('customerDetails', <StandardComponent> new CustomerDetailsComponent);