import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('bluetoothField', bluetoothField);

/**
 * Service constructor.
 * @constructor
 */
function bluetoothField() {
    let service = this;


    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'serviceUUID': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please enter a service UUID',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'characteristicUUID': {
                      'validation': {
                          'required': true,
                          'validationResponse': 'Please enter a characteristic UUID',
                          'requiredType': 'stringMustNotBeEmpty'
                      }
                },
                'connectToDeviceButtonText': {
                    'validation': {
                        'required': false
                    }
                },
                'buttonColour' : {
                    'validation': {
                        'required': false
                    }
                },
                'fontAwesomeIcon' : {
                    'validation': {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}