import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import FieldRuleHoverController from "components/field-rule-hover/controllers/fieldRuleHover.controller";

"use strict";

class FiledRuleHover extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/field-rule-hover';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        grid: '<',
        field: '<',
        key: '<',
        child: '<'
    }

    // This will set what will be the controller of this component
    public controller : any = FieldRuleHoverController;

    // This will set an alias
    public controllerAs: string = 'frh';
}

angular.module('BlocworxModule').component('fieldRulesHover', <StandardComponent> new FiledRuleHover);

