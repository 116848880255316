import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('blocworxGatewayField', blocworxGatewayField);

/**
 * Service constructor.
 * @constructor
 */
function blocworxGatewayField() {
    let service = this;


    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'gatewayUrl': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please enter a Gateway Url',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'applyOnNewSubmission': {
                    'validation': {
                        'required': false
                    }
                },
                'applyWhenEditing': {
                    'validation': {
                        'required': false
                    }
                },
                'manuallySendWithButton': {
                    'validation': {
                        'required': false
                    }
                },
                'manuallySendButtonText': {
                    'validation': {
                        'required': false
                    }
                },
                'buttonColour' : {
                    'validation': {
                        'required': false
                    }
                },
                'fontAwesomeIcon' : {
                    'validation': {
                        'required': false
                    }
                },
                'isRunOnSchedule' : {
                    'validation': {
                        'required': false
                    }
                },
                'selectFrequency' : {
                    'validation': {
                        'required': false
                    }
                },
                'selectTypeOfFrequency' : {
                    'validation': {
                        'required': false
                    }
                },
                'conditionType' : {
                    'validation': {
                        'required': false
                    }
                },
                'populateBlocFields' : {
                    'validation': {
                        'required': false
                    }
                },
                'triggerRulesOnFields' : {
                    'validation': {
                        'required': false
                    }
                },
                'resetTheForm' : {
                    'validation': {
                        'required': false
                    }
                },
                'reoadData' : {
                    'validation': {
                        'required': false
                    }
                },
                'sendAuthToken' : {
                    'validation': {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}