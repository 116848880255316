import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .controller('CustomerDetailsController', CustomerDetailsController);

// Injection of each class
CustomerDetailsController.$inject = ['$scope', 'ScopeAndLocal', 'Auth'];

// Controller Constructor
function CustomerDetailsController($scope, ScopeAndLocal, Auth) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            vm.auth = Auth;

            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            $scope.scanStation = vm.scanStation;

            ScopeAndLocal.startVariable(vm, $scope, 'field');
            $scope.field = vm.field;

            ScopeAndLocal.startVariable(vm, $scope, 'row');
            $scope.row = vm.row;

            ScopeAndLocal.startVariable(vm, $scope, 'fc');
            $scope.fc = vm.fc;

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            $scope.main = vm.main;


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Loading user details.
     *
     * @param field
     */
    vm.loadUserDetails = (field) => {

        if(field != null){
            vm.currentField = field;
        }

        vm.scanStation.domIndexes[vm.currentField.field_slug] = vm.currentField.fieldIndex;
        vm.scanStation.loggedInUsersName = vm.auth.userData.username;
        vm.scanStation.loggedInUsersEmail= vm.auth.userData.email

        // getting the correct value from the userData object.
        switch (field.userInfoRequired) {
            case 'full-name': // this will be for the name case
                vm.scanStation.scanStationObj.dataToAdd[vm.currentField.field_slug] = vm.auth.userData.name;
                break;
            default : // this will be for the name case
                vm.scanStation.scanStationObj.dataToAdd[vm.currentField.field_slug] = vm.auth.userData.email;
                break;
        }

        // at the end must check the rules for each vm.currentField.
        vm.scanStation.getRules('no-event',
            vm.currentField.id,
            vm.currentField.special_field_key,
            vm.scanStation.scanStationObj.dataToAdd[vm.currentField.field_slug],
            vm.currentField.fieldIndex,
            vm.currentField.field_slug
        );
    }

}

export default CustomerDetailsController;