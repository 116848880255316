import * as angular from 'angular';

"use strict";

angular
    .module('FieldModule')
    .factory('LinkFieldFactory', linkFieldFactory);


linkFieldFactory.$inject = [];


/**
 * Object that will create a Link Field Factory, all methods of factory
 * will be available from outside.
 */
function linkFieldFactory() {

    let factory = this;
    factory.linkData = {}

    /**
     * This function will be responsible to add a field Link
     *
     * @param linkArray
     * @param action
     * @param field
     * @param index
     */
    factory.addNewFieldLink = (linkArray, fieldId = null) => {

        if(fieldId == null) {
            alert('Please Select a Field');
            return linkArray;
        }

        if(typeof linkArray == 'undefined' || (typeof linkArray != 'undefined' && linkArray.length == 0)) {
            linkArray = [];
        }

        linkArray.push(fieldId);

        return linkArray;
    }

    /**
     * This will be responsible to remove a field link.
     *
     * @param linkArray
     * @param action
     * @param field
     * @param index
     */
    factory.removeFieldLink = (linkArray, index = null) => {

        if(index == null) {
            alert('Please Select a field');
            return linkArray;
        }

        delete linkArray[index];

        // build new object with fresh keys to keep it in sync
        let fieldListForLoop = linkArray;
        linkArray = [];
        let i = 0;
        angular.forEach(fieldListForLoop, function(value) {
            linkArray[i] = value;
            i++;
        });
        return linkArray;
    }

    /**
     * This will be responsible for building the json string that gets added to the end of the link in the results/records
     *
     * @param scanStationFieldIdsToField
     * @param fieldId
     * @param data
     */
    factory.buildJsonForSingleRecord = (scanStationFieldIdsToField, fieldId, data) => {
        let jsonObject = {};
        angular.forEach(scanStationFieldIdsToField[fieldId].fieldLinkArray, function (fieldIdWithData) {
            jsonObject[scanStationFieldIdsToField[fieldIdWithData].field_slug] = data[scanStationFieldIdsToField[fieldIdWithData].field_slug];
        });

        let jsonString = JSON.stringify(jsonObject);

        return jsonString;

    }

    /**
     * Method that load the link data variable.
     *
     * @param scanStationFieldIdsToField
     * @param fieldId
     * @param row
     */
    factory.loadLinkData = (scanStationFieldIdsToField, fieldId, row) => {

        let jsonObject = {};

        // building the json object
        angular.forEach(scanStationFieldIdsToField[fieldId].fieldLinkArray, function (fieldIdWithData) {
            jsonObject[scanStationFieldIdsToField[fieldIdWithData].field_slug] = row[scanStationFieldIdsToField[fieldIdWithData].field_slug];
        });

        let jsonString = JSON.stringify(jsonObject);

        factory.linkData['row_' + row.cartolytics_entry_row_id + '_field_id_' + fieldId] = jsonString;
    }

    /**
     *  Method that get data from link data variable.
     * @param row
     * @param field
     */
    factory.getLinkData = (row, field) => {
        return factory.linkData['row_' + row.cartolytics_entry_row_id + '_field_id_' + field.id];
    }

    /**
     * This will be responsible for building the json string that gets added to the end of the link on the form itself
     *
     * @param scanStationFieldIdsToField
     * @param dataToAdd
     */
    factory.rebuildLinkedDataJson = (scanStationFieldIdsToField, dataToAdd) => {

        let linkedDataFieldJson = [];
        angular.forEach(scanStationFieldIdsToField, function(field) {
           if(field.field_type == 'link-data-field') {

               linkedDataFieldJson['key_' + field.id] = {};

               angular.forEach(scanStationFieldIdsToField[field.id].fieldLinkArray, function (fieldIdWithData) {
                   linkedDataFieldJson['key_' + field.id][scanStationFieldIdsToField[fieldIdWithData].field_slug] = dataToAdd[scanStationFieldIdsToField[fieldIdWithData].field_slug];
               });

               linkedDataFieldJson['key_' + field.id] =  JSON.stringify(linkedDataFieldJson['key_' + field.id]);
           }
        });

        return linkedDataFieldJson;
    }


    return factory;
}



